import  "../alert.css";
import React from "react";
import { useState } from "react";

export default function Alert({ children, type, message, isShowInit, handleClose }) {
  
  const renderElAlert = function () {
    return React.cloneElement(children);
  };

  return (
    <div className={`alert ${type} ${!isShowInit ? 'hide' :'' }`}>
      <span className="closebtn" onClick={handleClose}>
        &times;
      </span>
      {children ? renderElAlert() : message}
    </div>
  );
}
