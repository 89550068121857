import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../utility/path";
import { spinner } from "./spinnerSlice";
import { getConfig, handleErrorResponse } from "../../services/functions";

// action
export const sendNotificationAction = createAsyncThunk(
    "notification/send",
    async (req, { rejectWithValue, getState, dispatch }) => {

        dispatch(spinner(true));

        const config = getConfig();
        
        fetch(`${BASE_URL}/api/notification/send`,{
            method:'POST',
            //headers: {"Content-Type": "application/json",},
            headers:config.headers,
            body:req
        })
        .then((res)=>{
            console.log(res);
            dispatch(spinner(false));
        })
        .catch((error)=>{
            console.log(error);
            const errorResponse = handleErrorResponse(error, dispatch);
            return rejectWithValue(errorResponse);
        })

      return "done";
    }
);


//slices
const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    data: {},
  },
    
});

export default notificationSlice.reducer;
