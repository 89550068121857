import { useState } from "react";
import Card from "../components/Card";

const ListItem = ({value,clear,handleClick}) => {
    let idx = 1;
    return (
        <>
        {value && clear && <li className="ratings clear" onClick={handleClick({})}>{value} <span>&times;</span></li>}
        {value && !clear && !handleClick && <li className="ratings" >{value}</li>}
        {value && !clear && handleClick && <li className="ratings" onClick={handleClick}>{value}</li>}
        </>
        
    );
  }
  
  export default ListItem;
  
