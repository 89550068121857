import {createAction, createReducer} from '@reduxjs/toolkit';

export const increment = createAction('increment/counter');
export const incrementByAmount = createAction('incrementByAmount/counter');

export const decrement = createAction('decrement/counter');


// using nuilder notation
const initialState = {
    value :0,
}
export const counterSlices = createReducer(initialState,
    builder => {
    builder.addCase(increment,(state,action)=>{
        state.value++;
    });
    builder.addCase(decrement,(state,action)=>{
        state.value--;
    });
    builder.addCase(incrementByAmount,(state,action)=>{
        state.value += action.payload;
    });
});
