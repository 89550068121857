import { useState } from "react";

const ErrorMessageFormik = ({formik,name}) => {
    return (
      <>
          {formik.touched[name] && formik.errors[name] ? (
                        <div className="text-danger">{formik.errors[name]}</div>
            ) : null}
      </>
    );
  }
  
  export default ErrorMessageFormik;
  
