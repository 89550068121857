import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import NavBar from "../components/Navigation/NavBar";
import MainContainer from "../components/MainContainer";
import { setHeader } from "../redux/slices/headerSlice";
import { SUBSCRIPTION_PATH } from "../utility/path";
import { displayDateTime, getConstant, getSubscriptionStatusLabel, handleHeader } from "../services/functions";
import CustomIcon from "../components/CustomIcon";


const SubscriptionDetails = ({ }) => {
    const payment = useSelector((state) => state.payment.data);
    const authData = useSelector((state)=> state.auth.data);
    const dispatch = useDispatch();

    useEffect(() => {
        handleHeader({...getConstant("SUBSCRIPTION_DETAILS")},dispatch);
        
        return () => { dispatch(setHeader({ gotoName: "Subscription", gotoAction: SUBSCRIPTION_PATH })); }
    }, []);

    return (
        <>
            <NavBar />
            <MainContainer>
                {payment?.transactionId ? (<p><b>Transaction ID:</b> {payment?.transactionId}</p>) : ''}
                <p><b>Amount:</b> <CustomIcon type="currency"/>{payment?.amount}</p>
                <p><b>Status:</b> {getSubscriptionStatusLabel(payment?.subscriptionStatus)}</p>
                {payment?.subscriptionStartDate ? (<p><b>Date:</b> {displayDateTime(payment?.subscriptionStartDate)}</p>) : ''}
            </MainContainer>
        </>
    );
}

export default SubscriptionDetails;
