import {createAction, createReducer} from '@reduxjs/toolkit';
import { HOME_PATH } from '../../utility/path';

export const setHeader = createAction('header/set');

// using nuilder notation
const initialState = {
    data: {gotoName:"Home",gotoAction:HOME_PATH}
}

export const headerSlice = createReducer(initialState,
    builder => {
    builder.addCase(setHeader,(state,action)=>{
        state.data = action.payload
    });
});
