import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../utility/path";
import { getConfig, handleErrorResponse } from "../../services/functions";

// action add
export const addAnalyticAction = createAsyncThunk(
  "analytic/add",
  async (user, { rejectWithValue, getState, dispatch }) => {
    console.log("add")
    
    const config = getConfig();
    
    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/analytic/add`,
        user,
        config
      );
      return data;

    } catch (error) {
        const errorResponse = handleErrorResponse(error, undefined);
        return rejectWithValue(errorResponse);
    }

  }
);


//slices
const analyticSlice = createSlice({
  name: "analytic",
  initialState: {
    data: {},
  },
  extraReducers: builder => {
    //add
    builder.addCase(addAnalyticAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addAnalyticAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addAnalyticAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});
  
export default analyticSlice.reducer;
  
