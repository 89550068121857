import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { getTokenFn } from "../firebase";
import { refreshProfileAction } from "../redux/slices/profilesSlice";
import { spinner } from "../redux/slices/spinnerSlice";
import { getConfig, urlBase64ToUint8Array } from "../services/functions";
import { BASE_URL, VAPID } from "../utility/path";
import { showAlert } from "../redux/slices/alertSlice";

const SubscribeNotification = ({notificationEnabled}) => {

    const profileData = useSelector(state => state?.profile?.data);
    const profileLoading = useSelector(state => state?.profile?.loading);

    const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [notificationSupported,setNotificationSupported] = useState(false);
    const dispatch = useDispatch();
   
  useEffect(()=>{
    checkForNotificationSupport();
  },[]);

  const checkForNotificationSupport = () =>{
    if('Notification' in window && 'serviceWorker' in navigator) {
      setNotificationSupported(true);
    }
  }

  const handleClick = ()=>{
    Notification.requestPermission((res)=>{
      var options={
        body:"Hello"
      }
      if(res === "granted"){
        //new Notification("message",options);
        //displayConfirmation();
        //getTokenFn(setTokenFound);
        confiurePushSubscription();
      
      }
    });
}

const confiurePushSubscription = () =>{
    if(!('serviceWorker' in navigator)){
        return;
    }
    const config = getConfig();

    //var notificationEnabled = true;
    var _swr;
    navigator.serviceWorker.ready
    .then(function(swr){
        _swr = swr;
       return  swr.pushManager.getSubscription();
    })
    .then(function(sub){
        if(sub==null){
            //create a new subscription
            var vapid = VAPID;
            var convertedVapid = urlBase64ToUint8Array(vapid);
            return _swr.pushManager.subscribe({userVisibleOnly:true, applicationServerKey:convertedVapid});

        }else{
            //we have a Subscription
            return sub;
        }
    })
    .then(function(newSubscription){
        dispatch(spinner(true));
        var s = JSON.stringify(newSubscription);
        var sn = JSON.parse(s);
        sn = {...sn,id:profileData.id,notificationEnabled:!notificationEnabled};
        s = JSON.stringify(sn);
       return fetch(`${BASE_URL}/api/notification/post`,{
            method:'POST',
            headers:config?.headers,
            body:s
        })
    })
    .then(function(res){
        if(res?.ok){
            //displayConfirmation();
            //update profile store
            res.json()
            .then((resp) => {dispatch(refreshProfileAction(resp));dispatch(showAlert({ message: 'Success', success: true }));})
            .catch((err)=> {console.log(err);dispatch(spinner(false));dispatch(showAlert({ message: 'Error', success: false }));})
        }
    })
    .catch(function(err){
        console.log('err',err);
        dispatch(spinner(false));
    })
    .finally(()=>{
      dispatch(spinner(false));
    })
}

  const displayConfirmation = () =>{
    if('serviceWorker' in navigator){
      
      var options = {
        body: 'You successfully subscribed to our Notification service!',
        icon: 'logo192.png',
        image: '/src/images/sf-boat.jpg',
        dir: 'ltr',
        lang: 'en-US', // BCP 47,
        vibrate: [100, 50, 200],
        badge: 'logo192.png',
        tag: 'confirm-notification',
        renotify: true,
        // actions: [
        //   { action: 'confirm', title: 'Okay', icon: 'logo192.png' },
        //   { action: 'cancel', title: 'Cancel', icon: 'logo192.png' }
        // ]
      };
  
      navigator.serviceWorker.ready
      .then((swr)=>{
        swr.showNotification('Successfully registered from SW',options);
      })
      .catch((err)=>{
        console.log('err',err)
      });
    }
  }

    return (
      <div>
        {notificationSupported ? (<h5>Manage Notifications</h5>) : ''}
        {notificationSupported ? <button onClick={handleClick} className="btn btn-outline-primary mb-3">{notificationEnabled ? "Disable" : "Enable"} Notification</button> : null}
      </div>
    );
  }
  
  export default SubscribeNotification;
