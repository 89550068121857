import { useState } from "react";
import Card from "../components/Card";

const H = ({value}) => {
    return (
        <>
            <h5>{value}</h5>
        </>
        
    );
  }
  
  export default H;
  
