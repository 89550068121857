import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/Navigation/NavBar";
import CustomInput from "../components/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, HOME_PATH, PROFILE_PATH } from "../utility/path";
import { getConfig, getConstant, handleErrorResponse, handleHeader, validatePincode, validatePincode_v2 } from "../services/functions";
import { spinner } from "../redux/slices/spinnerSlice";
import { setHeader } from "../redux/slices/headerSlice";
import axios from "axios";
import MainContainer from "../components/MainContainer";
import { showAlert } from "../redux/slices/alertSlice";

const ChangeAddress = ({ }) => {

  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  const [state, setState] = useState({
    address: data?.address,
    pincode: data?.pincode,
    id: data?.id,
    errorpincode:''
  });

  const handleChange = input => e => {

    if(input === 'pincode'){
      const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      const truncatedValue = numericValue.slice(0, 6); // Limit to 6 characters
      setState({ ...state, [input]: truncatedValue });
      if (truncatedValue?.length === 6) {
        validatePincode_v2(truncatedValue,setState);
      }
    }else{
      setState({ ...state, [input]: e.target.value });
    }
  }

  const handleClick = async (event) => {
    event.preventDefault();
    //dispatch(updateProfileAddressAction(state));
    dispatch(spinner(true));
    const config = getConfig();

    try {
      let formData = new FormData();
      formData.append("id", state.id);
      formData.append("address", state.address.trim());
      formData.append("pincode", state.pincode.trim());
      const { data } = await axios.post(`${BASE_URL}/api/users/updateProfileAddress`, formData, config);
      dispatch(setHeader({ gotoName: "Home", gotoAction: HOME_PATH }));
      dispatch(spinner(false));
      navigate(PROFILE_PATH);
      dispatch(showAlert({ message: data?.message , success: true }));
      return data;
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return errorResponse;
    }
  }

  useEffect(() => {
    handleHeader({...getConstant("CHANGE_ADDRESS")},dispatch);

    return () => {}
  }, []);

  const renderForm = () => {
    return (
      <>
        <CustomInput title="" id="addressId1" type="text" placeholder="Enter address" handleChange={(e) => handleChange(e)} name="address" value={state.address || ''} />
        <CustomInput title="" id="pincodeId1" type="number" placeholder="Enter pincode" handleChange={(e) => handleChange(e)} name="pincode" value={state.pincode || ''} state={state}/>
        {state?.postOffice && <p className="text-primary"><small>{state?.postOffice?.Name},&nbsp;</small><small>{state?.postOffice?.Division},&nbsp;</small><small>{state?.postOffice?.Region},&nbsp;</small><small>{state?.postOffice?.State},&nbsp;</small><small>{state?.postOffice?.Country}.</small></p>}
        
        <button type="button" className="btn btn-primary" onClick={(e) => handleClick(e)} disabled={state?.errorpincode ||profile?.loading || !state?.address || !validatePincode(state?.pincode)}>{profile?.loading ? "Loading.." : "Save"}</button>
      </>
    );
  }

  return (
    <>
      <NavBar />
      <MainContainer>
        {renderForm()}
      </MainContainer>
    </>
  );
}

export default ChangeAddress;
