import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/Alert";
import CustomInput from "../components/CustomInput";
import NavBar from "../components/Navigation/NavBar";
import { listCategoryAction, addCategoryAction } from "../redux/slices/categoriesSlice"
import MainContainer from "../components/MainContainer";
import { getConfig, getConstant, handleErrorResponse, handleHeader } from "../services/functions";
import { spinner } from "../redux/slices/spinnerSlice";
import { showAlert } from "../redux/slices/alertSlice";
import axios from "axios";
import { BASE_URL } from "../utility/path";

const Category = ({ }) => {
  const [state, setState] = useState({ name: '' });
  const categoriesData = useSelector(state => state?.categories?.data);
  const [categories,setCategoriesData] = useState(categoriesData || []);
  const authData = useSelector(state => state?.auth?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    handleHeader({...getConstant("CATEGORY")},dispatch);
    
    let ignore = false;
    if (!ignore && !categoriesData.length) {
      dispatch(listCategoryAction({ sample: "request" }));
    }

    return () => { ignore = true }
  }, [categoriesData]);
  
  // handle field change
  const handleChange = input => e => {
    setState({ ...state, [input]: e.target.value });
  }

  const handleChangeEdit = input => e => {
    setState({ ...state, [input]: e.target.value });
  }


  const Continue = e => {
    e.preventDefault();
    //setIsShowInit(true);
    if (!state || !state.name) {
      return;
    }

    dispatch(addCategoryAction({ ...state }));
    setState({ ...state, name: "" });


    // setTimeout(() => {
    //   console.log('timeout')
    //   setIsShowInit(false);
    // }, 3000);
  }

  const [isShowInit, setIsShowInit] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    setIsShowInit(false);
  };

  const [addNewCategory, setAddNewCategory] = useState(false);

  const handleEdit = (categoryId) => {
    setCategoriesData(prevState =>
      prevState.map(ct => {
        if (ct.id === categoryId) {
          setState({ ...state, [categoryId]: ct.name });
          return { ...ct, isEdit: true, [ct?.id] : ct?.name };
        }
        return ct;
      })
    );
  };

  const handleCancel = (categoryId,fieldname) => {
    setCategoriesData(prevState =>
      prevState.map(ct => {
        if (ct.id === categoryId) {
          setState({ ...state, [fieldname]: '' });
          return { ...ct, isEdit: false };
        }
        return ct;
      })
    );
  };

  const handleUpdate = id => async (e) => {
    e.preventDefault();
    console.log('name',e?.target?.name,'id',id,state?.[id]);

    if (!id || e?.target?.name === state?.[id].trim() ) {
      handleErrorResponse({message:'Invalid Request!!'}, dispatch);
      return;
    }
    
    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("id", id);
      formData.append("name", state?.[id].trim().toLowerCase());
      const { data } = await axios.put(`${BASE_URL}/api/categories/update`, formData, config);

      // Update categories in Redux store
      const updatedCategories = categories.map(ct => {
        if (ct.id === id) {
          return { ...ct, name: state?.[id]?.trim().toLowerCase(), isEdit: false };
        }
        return ct;
      });
    setCategoriesData(updatedCategories);

      dispatch(spinner(false));
      dispatch(showAlert({ message: data.message, success: true }));
      
      //initPlanData();
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  };



  const renderForm = () => {
    return (
      <>
        <div className="form-check form-switch mb-3">
          <input className="form-check-input"
            type="checkbox" role="switch" id="flexSwitchCheckChecked"
            onChange={() => setAddNewCategory(!addNewCategory)} />
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{addNewCategory ? 'Hide' : 'Add'} new category ?</label>
        </div>


        {addNewCategory ? (<>
          <CustomInput title="Add Category" id="categoryId" type="text" placeholder="Enter category" handleChange={handleChange} name="name" value={state?.name} />
          <button className="btn btn-primary mb-3" onClick={Continue} disabled={!state?.name}>Submit</button>
        </>) : ('')}

        {categories?.length > 0 && (<h5>Categories</h5>)}
        <ul className="list-scrollable">
          {categories?.map((ct) => (
            <li key={ct.id}>
              {!ct?.isEdit && (
              <div className="d-flex align-items-center justify-content-between m-1 p-1 bg-light capitalize">
                <div className="capitalize">{ct.name}</div>
                <div>
                  <button className="btn btn-outline-secondary btn-sm p-1 m-1" onClick={()=>handleEdit(ct?.id)}>Edit</button>
                </div>
              </div>)}
              {ct?.isEdit && (
              <div className="">
                <CustomInput title="Edit Category" id={`${ct.id}`} type="text" placeholder="Edit category" handleChange={handleChangeEdit} name={`${ct.id}`} value={state?.[ct?.id]} />
                <div>
                  <button className="btn btn-primary btn-sm p-1 m-1" name={`${ct.name}`} disabled={state?.[ct?.id] === ct?.name} onClick={handleUpdate(ct?.id)}>Update</button>
                  <button className="btn btn-primary btn-sm p-1 m-1" onClick={()=>handleCancel(ct.id,ct.id)}>Cancel</button>
                </div>
              </div>)}
              
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <>
      <NavBar />
      <MainContainer>
        {renderForm()}
      </MainContainer>

    </>
  );
}

export default Category;
