import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  Link,
  useNavigate,
  useLoaderData
} from "react-router-dom";
import NavBar from "../components/Navigation/NavBar";
import { decrement, increment, incrementByAmount } from "../redux/slices/counterSlice";
import { splash } from "../redux/slices/splashSlice";

//import { getUsers } from "../services/users";
import { useEffect, useState } from "react";
import {searchAction, usrAction} from "../redux/slices/usrSlice";
import CustomInput from "../components/CustomInput";
import CustomSearch from "../components/CustomSearch";
import { getGeoLocation } from "../services/functions";
import { locationSaveAction } from "../redux/slices/locationSlice";

import "../spinner.css";
import { listCategoryAction } from "../redux/slices/categoriesSlice";

function Spinner() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
    }

    return () => { ignore = true }
  }, []);

  return (
    <>
    <div>
        <div className="overlay show"></div>
        <div className="spanner show">
            <div className="loader"></div>
                <p>Loading...</p>
            </div>
    </div>
    </>
  );
}

export default Spinner;
