import {createAction, createReducer} from '@reduxjs/toolkit';

export const showAlert = createAction('alert/show');
export const hideAlert = createAction('alert/hide');

// using nuilder notation
const initialState = {
    show :false,
    data: undefined
}

export const alertSlice = createReducer(initialState,
    builder => {
    builder.addCase(showAlert,(state,action)=>{
        state.show = true;
        state.data = action.payload
    });
    builder.addCase(hideAlert,(state,action)=>{
        state.show = false;
        state.data = undefined
    });
});
