import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../utility/path";
import { updateDetailsAction } from "./detailsSlice";
import { spinner } from "./spinnerSlice";
import { getConfig, handleErrorResponse } from "../../services/functions";

// action list
export const postsAction = createAsyncThunk(
    "posts/list",
    async (user, { rejectWithValue, getState, dispatch }) => {
     
      const config = getConfig();

      try {
        const { data } = await axios.get(
          `${BASE_URL}/api/posts/list`,
          user,
          config
        );
  
        return data;
  
      } catch (error) {
        console.log(error);
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }

    }
);

// action add
export const addPostAction = createAsyncThunk(
  "posts/add",
  async (user, { rejectWithValue, getState, dispatch }) => {
    console.log("add post")
    dispatch(spinner(true));
   
    const config = getConfig();
    
    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/posts/add`,
        user,
        config
      );

      //let list = Object.assign([], getState()?.posts?.data);
      //list.push(data);
      dispatch(updateDetailsAction(data));
      return data;

    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }

  }
);

  
//slices
const postsSlices = createSlice({
  name: "posts",
  initialState: {
    data: [],
  },
  extraReducers: builder => {
    //list posts
    builder.addCase(postsAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(postsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(postsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });


    //add post
    builder.addCase(addPostAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addPostAction.fulfilled, (state, action) => {
      //console.log('action?.payload',action?.payload, state)
      state.loading = false;
      state.data.push[ action?.payload];
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addPostAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});
  
export default postsSlices.reducer;
  
