import React, { useEffect, useState } from "react";
import NavBar from "../components/Navigation/NavBar";
import { getConfig, getConstant, handleHeader } from "../services/functions";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import MainContainer from "../components/MainContainer";
import { useDispatch } from "react-redux";

const AboutUs = () => {
    const [state, setState] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        handleHeader({...getConstant("ABOUT_US")},dispatch);
        loadData();

    }, []);

    const loadData = async () => {
        const data = JSON.parse(localStorage.getItem("aboutus"));
        if (data && data?.length > 0) {
            setState(data);
            return;
        }

        try {
            const config = getConfig();
            const res = await axios.get(`${BASE_URL}/api/aboutus`, config);
            if (res?.data?.paras) {
                localStorage.setItem("aboutus", JSON.stringify(res.data.paras));
                setState(res.data.paras);
            }

        } catch (error) {
            console.log(error?.message)
        }
    }

    return (

        <>
            <NavBar />
            <MainContainer>
                <h3>About Us</h3>
                {
                    state.map((el, index) => (
                        <p key={index}>{el}</p>
                    ))
                }
            </MainContainer>
        </>
    );
};

export default AboutUs;
