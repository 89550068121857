import { useState } from "react";
import ErrorMessageFormik from "./ErrorMessageFormik";

const CustomCheckbox = ({name, onChange, value, id, label, validate, formikError, formik}) => {
    return (
      <>
        {validate ? (<div className="form-check">
            <input name={name} type="checkbox" onChange={onChange} value={value} 
            id={id}/>
            <label className="form-check-label capitalize pl-1" htmlFor={id}>{label}</label>
            
        </div>) : null}
        
      </>
    );
  }
  
  export default CustomCheckbox;

