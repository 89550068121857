import React, { useEffect, useState } from "react";
import NavBar from "../components/Navigation/NavBar";
import { getConfig, getConstant, getPlanAmount, getPlanLabel, handleHeader } from "../services/functions";
import { BASE_URL, CONTACT_US_PATH, PRIVACY_POLICY_PATH } from "../utility/path";
import axios from "axios";
import MainContainer from "../components/MainContainer";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import CustomIcon from "../components/CustomIcon";

const TnC = () => {
    const [state, setState] = useState([]);
    const dispatch = useDispatch();
    

    useEffect(() => {
        handleHeader({...getConstant("TNC")},dispatch);
        //loadData();

    }, []);

    const loadData = async () => {
        const data = JSON.parse(localStorage.getItem("aboutus"));
        if (data && data?.length > 0) {
            setState(data);
            return;
        }

        try {
            const config = getConfig();
            const res = await axios.get(`${BASE_URL}/api/aboutus`, config);
            if (res?.data?.paras) {
                localStorage.setItem("aboutus", JSON.stringify(res.data.paras));
                setState(res.data.paras);
            }

        } catch (error) {
            console.log(error?.message)
        }
    }

    return (

        <>
            <NavBar />
            <MainContainer>
                <h3>Terms & Conditions</h3>
                <p>
                    <h5>1. Introduction</h5>
                    Welcome to "Gaufi" ("the Platform"). "Gaufi" is designed, developed, maintained and owned by <a href="https://www.softneering.com/" target="_blank">"Softneering Technologies Solution Private Limited"</a>. By accessing or using our Platform, you agree to comply with and be bound by these Terms and Conditions ("Terms"). 
                    If you do not agree to these Terms, you should not use the Platform.
                </p>
                <p>
                    <h5>2. Description of Services</h5>
                    The Platform provides a service that helps users ("Users") to search and connect with nearby freelance professionals ("Professionals") such as carpenters, electricians, helpers, etc. 
                    The Platform serves as a facilitator and is not responsible for the services provided by the Professionals.
                </p>
                <p>
                    <h5>3. User Responsibilities</h5>
                    <b>Use of Services:</b> Users are solely responsible for deciding whether to engage with the Professionals listed on the Platform.
                    <br/>
                    <b>Verification:</b> Users should independently verify the credentials and qualifications of any Professional before hiring them.
                    <br/>
                    <b>Conduct:</b> Users agree to use the Platform in a lawful manner and not to engage in any conduct that could harm the reputation or functionality of the Platform.
                </p>
                <p>
                    <h5>4. Professional Account Creation</h5>
                    <b>Registration:</b> Professionals can create an account by providing minimum required information, including name, contact details, and profession.
                    <br/>
                    <b>Accuracy of Information:</b> Professionals must ensure that the information provided is accurate and up-to-date.
                    <br/>
                    <b>Subscription Plans:</b> Professionals can opt for various subscription plans that may offer additional benefits and help them get more business opportunities.
                </p>
                <p>
                    <h5>5. Subscription Plans Options</h5>
                    The Platform offers different subscription plans for Professionals, which may include but are not limited to.
                    <br/>
                    <b>{getPlanLabel(1)}:</b> Access to the Platform with limited features. Just pay <b><CustomIcon type="currency"/>{getPlanAmount(1)}</b> for one year validity.
                    <br/>
                    <b>{getPlanLabel(2)}:</b> Enhanced visibility and access to additional features. Just pay <b><CustomIcon type="currency"/>{getPlanAmount(2)}</b> for two years validity.
                    <br/>
                    <b>{getPlanLabel(3)}:</b> Maximum visibility and access to all features, including priority support. Just pay <b><CustomIcon type="currency"/>{getPlanAmount(3)}</b> for three years validity.
                </p>
                <p>
                    <h5>6. Subscription Fees</h5>
                    <b>Payment:</b> Subscription fees are charged as per the selected plan and are required to keep the Platform operational.
                    <br/>
                    <b>Payment Methods:</b> The Platform accepts various payment methods including credit/debit cards and online payment services.
                    <br/>
                    <b>Changes to Fees:</b> The Platform reserves the right to change subscription fees at any time. Any fee change will become effective at the end of the current billing cycle. Professionals will be notified in advance of any changes to the fees.
                </p>
                <p>
                    <h5>7. Cancellation and Refunds</h5>
                    <b>Cancellation:</b> Professionals can cancel their subscription at any time by contacting us.
                    <br/>
                    <b>Refund Policy:</b> Subscription fees are generally non-refundable.
                </p>
                <p>
                    <h5>8. Platform's Role and Liability</h5>
                    <b>Facilitation:</b> The Platform acts as a facilitator connecting Users with Professionals and does not endorse or guarantee the quality of services provided by Professionals.
                    <br/>
                    <b>No Warranty:</b> The Platform is provided "as is" and "as available" without any warranties of any kind, either express or implied.
                    <br/>
                    <b>Liability:</b> The Platform is not liable for any direct, indirect, incidental, or consequential damages arising out of or related to the use of the Platform or services provided by Professionals.
                        
                </p>
                <p>
                    <h5>9. Termination</h5>
                    <b>Termination by User:</b> Users may terminate their account at any time by contacting customer support.
                    <br/>
                    <b>Termination by Platform:</b> The Platform reserves the right to terminate or suspend any account at its discretion, particularly in cases of violation of these Terms.
                    
                </p>
                <p>
                    <h5>10. Changes to Terms</h5>
                    The Platform reserves the right to modify these Terms at any time. Users will be notified of any significant changes, and continued use of the Platform will constitute acceptance of the revised Terms.
                </p>
                <p>
                    <h5>11. Governing Law</h5>
                    These Terms shall be governed by and construed in accordance with the laws of India.
                </p>
                <p>
                    <h5>12. Contact Information</h5>
                    For any questions or concerns about these Terms, please contact us at <NavLink to={CONTACT_US_PATH}>Contact us</NavLink>.
                </p>
                <p>
                    <h5>13. Privacy Policy</h5>
                    We strongly recommend to refer to <NavLink to={PRIVACY_POLICY_PATH}>Privacy Policy here</NavLink>.
                </p>
            </MainContainer>
        </>
    );
};

export default TnC;
