import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useLocation,
    useNavigate
} from "react-router-dom";

import NavBar from "../components/Navigation/NavBar";

import CustomIcon from "../components/CustomIcon";
import { spinner } from "../redux/slices/spinnerSlice";
import { getConfig, getConstant, getSubscriptionStatusLabel, handleErrorResponse, handleHeader } from "../services/functions";
import axios from "axios";
import { BASE_URL, SUBSCRIPTION_DETAILS_PATH, SUBSCRIPTION_PATH } from "../utility/path";
import { getPaymentRequestStatus } from "../redux/slices/paymentSlice";
import MainContainer from "../components/MainContainer";

const Subscription = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const authData = useSelector(state => state?.auth?.data);

    const [plan, setPlan] = useState(1);
    const [transactions, setTransactions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [cssColor, setCssColor] = useState('');
    const [cssColorForTab, setCssColorForTab] = useState('btn-outline-success');

    //const redirectUrl = window.location.origin + SUBSCRIPTION_PATH;
    const redirectUrl = window.location.origin;
    const [amount] = useState(99);
    const [discountForPlan2] = useState(10);
    const [discountForPlan3] = useState(20);

    const ALLOW_NEW_TRANSACTIONS = false;
    

    useEffect(() => {
        handleHeader({...getConstant("SUBSCRIPTION")},dispatch);
        
        const txnId = searchParams && searchParams.get("txnId");
        const plan = searchParams && searchParams.get("plan");

        if(txnId){
            dispatch(getPaymentRequestStatus({ txnId, id: authData?.id, type: "transaction",plan }));
            navigate(SUBSCRIPTION_DETAILS_PATH);
        }else{
            getTransactions();
        }

        return () => { handleHeader({...getConstant("HOME")},dispatch); }
    }, []);

    useEffect(() => {
        return () => { }
    }, [plan, cssColor, disableButton]);

    const checkTransacitonStatus = (data) => {
        if (!data) {
            return false;
        }
        const hasPendingTransactions = data.some((r) => r.subscriptionStatus === 2 || r.subscriptionStatus === 0 || r?.state === 'PENDING'); // pending or inactive
        const hasActiveSubscriptions = data.some((r) => r.subscriptionStatus === 1); // active

        if (hasPendingTransactions || hasActiveSubscriptions) {
            const firstPendingTransaction = data.find((r) => r.subscriptionStatus === 2 || r.subscriptionStatus === 0);  // not active
            const firstActiveSubscription = data.find((r) => r.subscriptionStatus === 1);

            if (firstActiveSubscription) {
                setPlan(firstActiveSubscription.plan);
                setCssColor('bg-success text-white');
            } else if (firstPendingTransaction?.subscriptionStatus === 2 || firstPendingTransaction?.subscriptionStatus === 0) {
                setPlan(firstPendingTransaction.plan);
                setCssColor('bg-warning text-white');
                setCssColorForTab('btn-outline-warning')
            }
            setDisableButton((state) => { return !ALLOW_NEW_TRANSACTIONS });
            return true;
        }

        return false;
    }

    const getStarted = async (event) => {
        //return;
        event.preventDefault();
        
        if(ALLOW_NEW_TRANSACTIONS){

        }else if (!authData?.id || checkTransacitonStatus(transactions)) {
            return;
        }

        try {
            dispatch(spinner(true));
            const config = getConfig();
            let formData = new FormData();
            formData.append("user", authData?.id);
            formData.append("plan", plan);
            formData.append("redirect_url", redirectUrl);

            const res = await axios.post(`${BASE_URL}/api/payments/createPayment`, formData, config);
            dispatch(spinner(false));
            window.location.href = res?.data?.data?.instrumentResponse?.redirectInfo?.url;
        } catch (error) {
            console.log(error?.message)
            dispatch(spinner(false));
            handleErrorResponse(error, dispatch);
        }
    }

    const getTransactions = useCallback(async () => {
        if (!authData?.id) {
            return;
        };

        try {
            const config = getConfig();
            let formData = new FormData();
            formData.append("user", authData?.id);
            const res = await axios.get(`${BASE_URL}/api/payments/getTransactions`, { ...config, params: { "user": authData?.id } });
            setTransactions(res.data);
            checkTransacitonStatus(res.data);
            await refreshStatus(res.data);

        } catch (error) {
            console.log(error?.message);
            const errorResponse = handleErrorResponse(error, dispatch);
        }
    }, [authData, dispatch]);


    const refreshStatus = async (data) => {
        
        try {
            await Promise.all(data.map(async (element) => {
                if(element?.state === 'PENDING'){
                    await dispatch(getPaymentRequestStatus({ 
                        txnId: element?.paymentRequestId, 
                        id: authData?.id, 
                        type: "enquiry",
                        plan: element?.plan,
                        state:element?.state,
                        ptid:element?.id
                    }));
                }
            }));
        } catch (error) {
            console.log(error?.message);
        }
    }    

    const renderForm = () => {
        return (
            <>
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">

                    {plan !== 1 && disableButton ? '' : (<><input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => setPlan(1)} checked={plan === 1} disabled={plan !== 1 && disableButton} />
                    <label className={`btn ${plan === 1 && disableButton ? cssColorForTab : 'btn-outline-primary'}`} htmlFor="btnradio1">Starter plan</label></>)}

                    {plan !== 2 && disableButton ? '' : (<><input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onChange={() => setPlan(2)} checked={plan === 2} disabled={plan !== 2 && disableButton} />
                    <label className={`btn ${plan === 2 && disableButton ? cssColorForTab : 'btn-outline-primary'}`} htmlFor="btnradio2">Medium term plan</label></>)}

                    {plan !== 3 && disableButton ? '' : (<><input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" onChange={() => setPlan(3)} checked={plan === 3} disabled={plan !== 3 && disableButton} />
                    <label className={`btn ${plan === 3 && disableButton ? cssColorForTab : 'btn-outline-primary'}`} htmlFor="btnradio3">Long term plan</label></>)}
                    
                </div>

                {plan === 1 ? <div className="mt-3">
                    <div className="card">
                        <div className={`card-header ${disableButton ? `${cssColor}` : ''}`}>Starter plan</div>
                        <div className="card-body">
                            <h1 className="card-title"><CustomIcon type="currency" />{amount  * 1 }</h1>
                            <ul style={{listStyleType:'disc'}} className="p-2">
                                <li>12 months plan</li>
                                <li>Start with minimal subscription amount</li>
                                <li>Increased probability of your account appearing in the search results</li>
                                <li>Prioritized placement of your account in the search</li>
                                <li>Faster response to any support queries</li>
                                <li>Personalized recommendations from our team to maximize the benefits of your subscription</li>
                            </ul>
                        </div>
                        {!disableButton ? <div className="card-footer"><button type="button" className="btn btn-lg btn-block btn-outline-primary" onClick={getStarted}>Get started</button></div> : ''}
                    </div>
                </div> : null}
                {plan === 2 ? <div className="mt-3">
                    <div className="card">
                        <div className={`card-header ${disableButton ? `${cssColor}` : ''}`}>Medium term plan</div>
                        <div className="card-body">
                            <h1 className="card-title"><CustomIcon type="currency" />{Math.round(amount * 2 - amount * 2 * (discountForPlan2 / 100))}</h1>
                            <ul style={{listStyleType:'disc'}} className="p-2">
                                <li>24 months plan</li>
                                <li>Saving <b className="bg-warning">{discountForPlan2}%</b> of subscription amount in a year</li>
                                <li>Pay once and don't worry about the next payment for a much longer time</li>
                                <li>Increased probability of your account appearing in the search results</li>
                                <li>Prioritized placement of your account in the search</li>
                                <li>Faster response to any support queries</li>
                                <li>Personalized recommendations from our team to maximize the benefits of your subscription</li>
                            </ul>
                        </div>
                        {!disableButton ? <div className="card-footer"><button type="button" className="btn btn-lg btn-block btn-outline-primary" onClick={getStarted}>Get started</button></div> : ''}
                    </div>
                </div> : null}
                {plan === 3 ? <div className="mt-3">
                    <div className="card">
                        <div className={`card-header ${disableButton ? `${cssColor}` : ''}`}>Long term plan</div>
                        <div className="card-body">
                            <h1 className="card-title"><CustomIcon type="currency" />  {Math.round(amount * 3 - amount * 3 * (discountForPlan3 / 100))}</h1>
                            <ul style={{listStyleType:'disc'}} className="p-2">
                                <li>36 months plan</li>
                                <li>Saving <b className="bg-warning">{discountForPlan3}%</b> of subscription amount in a year</li>
                                <li>Pay once and don't worry about the next payment for a much longer time</li>
                                <li>Increased probability of your account appearing in the search results</li>
                                <li>Prioritized placement of your account in the search</li>
                                <li>Faster response to any support queries</li>
                                <li>Personalized recommendations from our team to maximize the benefits of your subscription</li>
                            </ul>
                        </div>
                        {!disableButton ? <div className="card-footer"><button type="button" className="btn btn-lg btn-block btn-outline-primary" onClick={getStarted}>Get started</button></div> : ''}
                    </div>
                </div> : null}
            </>
        );
    }

    return (
        <>
            <NavBar />
            <MainContainer>
                {renderForm()}
                <div id="accordion" className="mt-3 mb-3">
                    <h5>Transactions history</h5>
                    {
                        transactions.map((r, index) => (
                            <div className="card" key={r.id}>
                                <div className="card-header" id={`headingOne_${r.id}`}>
                                    <h5 className="mb-0">
                                        <button className="btn link-primary collapsed" data-toggle="collapse" data-target={`#collapseOne_${r.id}`} aria-expanded="false" aria-controls={r.id}>
                                            {r.purpose} {"(Status: "+getSubscriptionStatusLabel(r.subscriptionStatus)+")"}
                                        </button>
                                    </h5>
                                </div>

                                <div id={`collapseOne_${r.id}`} className="collapse" aria-labelledby={`headingOne_${r.id}`} data-parent="#accordion">
                                    <div className="card-body">
                                        <ul className="p-1">
                                            {r?.transactionId ? (<li>Transaction ID: {r.transactionId}</li>) : ''}
                                            <li>
                                                Transaction date: {new Date(r.updatedAt).toLocaleString()} 
                                            </li>
                                            <li>
                                                {r?.subscriptionEndDate ? 'Subscription end date: ' + new Date(r.subscriptionEndDate).toLocaleDateString() : ''}
                                            </li>
                                            <li>
                                                {"Status: "+getSubscriptionStatusLabel(r.subscriptionStatus)}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {!transactions || !transactions.length && <div>No transactions</div>}
                </div>
            </MainContainer>
        </>
    );
}

export default Subscription;

