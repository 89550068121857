import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import { getUsers } from "../../services/users";
import verifyMobileRes from '../../mock/verfiyMobileRes.json';
import verfiyOtpRes from '../../mock/verfiyOtpRes.json';
import setNameRes from '../../mock/setNameRes.json';
import setPasswordRes from '../../mock/setPasswordRes.json';
import verifyPasswordRes from '../../mock/verifyPasswordRes.json';
import {BASE_URL} from "../../utility/path";
import axios from "axios";
import { splash } from "./splashSlice";
import { spinner } from "./spinnerSlice";
import { showAlert } from "./alertSlice";
import { encryptPassword, handleErrorResponse } from "../../services/functions";
import { getDeviceId } from "../../services/functions";


// action
export const logoutAction = createAsyncThunk(
    "auth/logout",
    async (user, { rejectWithValue, getState, dispatch }) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      
      console.log("logoutAction",user);

      localStorage.removeItem("userInfo");
      localStorage.setItem("location",JSON.stringify({latitude:-1,longitude:-1}));      

      return {
      name:undefined,
      isAdmin:false,
      role:undefined,
      id:undefined,
      nextStep:1,
      isLoggedIn:false,
    };

     // return verifyMobileRes;
    }
);

export const previousAction = createAsyncThunk(
  "auth/previous",
  async (currentStep, { rejectWithValue, getState, dispatch }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("previousAction",currentStep)

    return {
    name:undefined,
    isAdmin:false,
    role:undefined,
    id:undefined,
    nextStep:currentStep,
    isLoggedIn:false,
  };

   // return verifyMobileRes;
  }
);

export const verifyMobileAction = createAsyncThunk(
  "auth/verifyMobile",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
        "deviceId":localStorage.getItem("deviceId")
      },
    };

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/users/verifyMobile`,
        user,
        config
      );

      dispatch(spinner(false));

      return data;
    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);
    }


   // return verifyMobileRes;
  }
);

//verify password action
export const verifyPasswordAction = createAsyncThunk(
  "auth/verifyPassword",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        "deviceId":localStorage.getItem("deviceId")
      },
    };

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/users/login`,
        {...user,password:encryptPassword(user.password)},
        config
      );

      
      //localStorage.removeItem("userInfo");
      localStorage.setItem("userInfo",JSON.stringify(data));
      dispatch(spinner(false));
      
      return data;
    } catch (error) {
      console.log(error);
      dispatch(splash(false));
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }
    //return verifyPasswordRes;
  }
);

//verify otp action
export const verifyOtpAction = createAsyncThunk(
  "auth/verifyOtp",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
        "deviceId":localStorage.getItem("deviceId")
      },
    };
    //return verfiyOtpRes;

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/users/verifyOtp`,
        user,
        config
      );

      dispatch(spinner(false));

      return data;
    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }
  }
);

//set name action
export const setNameAction = createAsyncThunk(
  "auth/setName",
  async (user, { rejectWithValue, getState, dispatch }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "deviceId":localStorage.getItem("deviceId")
      },
    };
    //return {nextStep:5,status:"success",user: user};
    return setNameRes;
  }
);

//set name action
export const setPasswordAction = createAsyncThunk(
  "auth/setPassword",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
        "deviceId":localStorage.getItem("deviceId")
      },
    };
    const deviceId = localStorage.getItem("deviceId");

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/users/register`,
        {...user,password:encryptPassword(user.password),confirmPassword:encryptPassword(user.confirmPassword),deviceId},
        config
      );

      //localStorage.removeItem("userInfo");
      localStorage.setItem("userInfo",JSON.stringify(data));

      //const { data } = jwtDecode(token);
      //console.log(`Logged in as ${data}`);

      //dispatch(splash(true));
      dispatch(spinner(false));


      return data;
    } catch (error) {
      console.log(error)
      dispatch(spinner(false));
      dispatch(splash(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      console.log('errorResponse',errorResponse);

        return rejectWithValue(errorResponse);
    }

   

    //return setPasswordRes;        
  }
);

export const updateAuthDataAction = createAsyncThunk(
  "auth/updateAuthData",
  async (data, { rejectWithValue, getState, dispatch }) => {
    
    const authData = getState().auth?.data;
    const newAuthData = {
      ...authData,profilePhoto:data.profilePhoto
    };
    return newAuthData;
  }
);

//get user from local storage and place into store
//localStorage.removeItem("unserInfo");

const userLoginFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : {nextStep:1,isLogggedIn:false};

  
//slices
const authSlices = createSlice({
  name: "auth",
  initialState: {
    data: userLoginFromStorage,
  },
  extraReducers: builder => {
    
    //reducer
    builder.addCase(verifyMobileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyMobileAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyMobileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.data = action?.payload;
    });

     //verify password
     builder.addCase(verifyPasswordAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyPasswordAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyPasswordAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.data = action?.payload;
    });

    //verify otp
    builder.addCase(verifyOtpAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyOtpAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyOtpAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.data = action?.payload;
    });

    //set name
    builder.addCase(setNameAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(setNameAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(setNameAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });


    //set password
    builder.addCase(setPasswordAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(setPasswordAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(setPasswordAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.data = action?.payload;
    });

    // logout 
    builder.addCase(logoutAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(logoutAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

     //reducer
     builder.addCase(previousAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(previousAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(previousAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

     //reducer
     builder.addCase(updateAuthDataAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateAuthDataAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateAuthDataAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

  },
});
  
export default authSlices.reducer;
  
