import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConfig, getConstant, handleErrorResponse, handleHeader, inputTypeNumber, validateMobile } from "../services/functions";
import MainContainer from "../components/MainContainer";
import { spinner } from "../redux/slices/spinnerSlice";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import { setHeader } from "../redux/slices/headerSlice";
import NavBar from "../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../redux/slices/alertSlice";

const ContactUs = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth.data);

  const [state, setState] = useState({});

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("user", authData?.id);
      formData.append("name", state.name || authData.name || '');
      formData.append("mobile", state.mobile);
      formData.append("message", state.message);
      
      const { data } = await axios.post(`${BASE_URL}/api/contactus`, formData, config);
      dispatch(spinner(false));
      //navigate(CONTACT_US_PATH);
      dispatch(showAlert({ message: data.message, success: true }));
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  }

  const handleChange = input => e => {
    e.preventDefault();
    if(input == 'mobile') {
      const truncatedValue = inputTypeNumber(e.target.value,10);
      setState({ ...state, [input]: truncatedValue });  
    }else{
      setState({ ...state, [input]: e.target.value });
    }
  }

  useEffect(() => {
    handleHeader({...getConstant("CONTACT_US")},dispatch);

    return () => { }
  }, [])

  return (
    <>
      <NavBar />
      <MainContainer>
      <h3>Contact Us</h3>
        {authData?.id ? 
        <>
          <CustomInput title="Name" id="nameId" type="text" placeholder="Name" handleChange={handleChange} name="name" value={authData?.name} isDisable={true} addCssToInput={"capitalize"}/>
          <CustomInput title="Message" id="messageId" type="text" placeholder="Type your message" handleChange={handleChange} name="message" value={state?.message} />
          <button className="btn btn-primary m-2" onClick={(e) => handleClick(e)} disabled={(!state?.message || !state?.message?.length > 200)} >Submit</button>
        </>
         :
         <>
          <CustomInput title="Name" id="nameId" type="text" placeholder="Enter name" handleChange={handleChange} name="name" value={state?.name} addCssToInput={"capitalize"} />
          <CustomInput title="Mobile" id="mobileId" type="number" placeholder="Enter mobile" handleChange={handleChange} name="mobile" value={state?.mobile}/>
          <CustomInput title="Message" id="messageId" type="text" placeholder="Type your message" handleChange={handleChange} name="message" value={state?.message} />
          <button className="btn btn-primary m-2" onClick={(e) => handleClick(e)} disabled={(!state?.name || !validateMobile(state?.mobile) || !state?.message || state?.message?.length > 200)} >Submit</button>
         </> 
         }
      </MainContainer>
    </>
  );
}

export default ContactUs;
