import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConfig, getConstant, handleErrorResponse, handleHeader, inputTypeNumber, validateMobile } from "../services/functions";
import MainContainer from "../components/MainContainer";
import { spinner } from "../redux/slices/spinnerSlice";
import { BASE_URL} from "../utility/path";
import axios from "axios";
import { setHeader } from "../redux/slices/headerSlice";
import NavBar from "../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../redux/slices/alertSlice";
import CustomIcon from "../components/CustomIcon";

const ManageNotification = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth.data);

  const [state, setState] = useState({});
  const [plans, setPlans] = useState([]);
  const [data, setData] = useState([]);
  const [messages, setAddMessage] = useState([]);

  const handleClick = async (e) => {
    e.preventDefault();

    if (!state?.title || !state.message) {
      const errorResponse = handleErrorResponse({ message: "Invalid Request!!" }, dispatch);
      return;
    }

    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("title", state.title.trim());
      formData.append("message", state?.message);

      const { data } = await axios.post(`${BASE_URL}/api/notification/g/add`, formData, config);
      dispatch(spinner(false));
      dispatch(showAlert({ message: data.message, success: true }));
      initData();
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  }

  const handleChange = input => e => {
    //e.preventDefault();
    if (input == 'amount') {
      const truncatedValue = inputTypeNumber(e.target.value, 6);
      setState({ ...state, [input]: truncatedValue });
    } else {
      setState({ ...state, [input]: e.target.value });
    }
  }

  useEffect(() => {
    console.log('useeffect')
    handleHeader({ ...getConstant("MANAGE_NOTIFICATION") }, dispatch);
    initData();
    return () => { }
  }, []);

  const initData = async () => {
    //const data = localStorage.getItem("subscriptionmasterplan") && JSON.parse(localStorage.getItem("subscriptionmasterplan"));
    // if (data) {
    //     setPlans(data);
    //     return;
    // }

    try {
      const config = getConfig();
      const res = await axios.get(`${BASE_URL}/api/notification/g/list`, config);
      
      if (res?.data?.length) {
        //localStorage.setItem("subscriptionmasterplan", JSON.stringify(res.data));
        setData(res.data);
      }
      
    } catch (error) {
      console.log(error?.message);
      setData([]);
    }
  }
  
  const handleDeletePlan = async (index) => {
    if (!index) {
      return;
    }
    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("id", index);

      const { data } = await axios.put(`${BASE_URL}/api/notification/g/put`, formData, config);
      dispatch(spinner(false));
      dispatch(showAlert({ message: data.message, success: true }));
      initData();
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  };


  return (
    <>
      <NavBar />
      <MainContainer>
        <h3>Manage Notifications</h3>
        {authData?.id ?
          <>
            <CustomInput title="Title" id="titleId" type="text" placeholder="Title" handleChange={handleChange} name="title" value={state?.title} addCssToInput={"capitalize"} />
            <CustomInput title="Message" id="messageId" type="text" placeholder="Type your message" handleChange={handleChange} name="message" value={state?.message} />
            
            <button className="btn btn-primary mb-2" onClick={(e) => handleClick(e)} disabled={(!state?.message || !state.title)} >Submit</button>
            
            <div className="card mt-2">
              <div className="card-header">
                <h5>Notifications</h5>
              </div>
              <div id="accordion">
                {
                  data?.map((r, index) => (
                    <div className="card" key={r.id}>
                      <div className="card-header" id={`headingOne_${r.id}`}>
                        <h5 className="mb-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <button className="btn link-primary collapsed capitalize" data-toggle="collapse" data-target={`#collapseOne_${r.id}`} aria-expanded="false" aria-controls={r.id}>
                            {r.title}
                          </button>
                          <button className="btn btn-outline-danger btn-sm mb-2" onClick={() => handleDeletePlan(r.id)}>Delete</button>
                        </h5>
                      </div>

                      <div id={`collapseOne_${r.id}`} className="collapse" aria-labelledby={`headingOne_${r.id}`} data-parent="#accordion">
                        <div className="card-body">
                          <div>{r.title}</div>
                          <div>{r.message}</div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {!data || !data.length && <div className="card-body">No records found</div>}
              </div>
            </div>
          </>
          : ''}
      </MainContainer>
    </>
  );
}

export default ManageNotification;
