// server
import { isMobile } from "../services/functions";

// to run local env.
//export const BASE_URL =  isMobile() ? "https://10.0.2.2:5000" : "http://localhost:5000";

// to run on server env.
export const BASE_URL =  isMobile() ? "https://10.0.2.2:5000" : "https://server-2z7t.onrender.com";

//export const BASE_URL = "https://localhost:5000";
//export const BASE_URL = "https://192.168.0.2:5000";
//export const BASE_URL = "https://10.0.2.2:5000";

export const HOME_PATH = "/";
export const LOGIN_PATH = "/login/0";
export const REGISTER_PATH = "/journey/0";
export const REGISTER_GENERAL_PATH = "/journeygeneral/0";
export const FORGOT_PWD_PATH = "/forgotpwd/0";
export const DETAILS_PATH = "/details/:id";
export const PROFILE_PATH = "/profile";
export const CATEGORY_PATH = "/category";
export const CHANGE_ADDRESS_PATH = "/chgaddress";
export const CHANGE_PASSWORD_PATH = "/chgpwd";
export const CHANGE_IMAGE_PATH = "/chgimage";
export const CHANGE_SERVICE_PATH = "/chgservice";
export const PUSH_NOTIFICATION_PATH = "/pushnotification";
export const SUBSCRIPTION_PATH = "/subscription";
export const SUBSCRIPTION_DETAILS_PATH = "/subscriptionDetails";
export const LOGOUT_PATH = "/journey/-1";
export const CONTACT_US_PATH = "/contactus";
export const ABOUT_US_PATH = "/aboutus";
export const NOTIFCATION_PATH = "/notification";
export const MANAGE_SUBSCRIPTION_PLAN_PATH = "/subscriptionplanmaster";
export const MANAGE_NOTIFICATION_PATH = "/managenotification";
export const VAPID = "BMXz-umAUVdil_opihkll_q2fVz-UtSHKn0-bgO6wdm-cEgfpwyFtamwVSnnXnQD7-4BvSEqO7w5k2g6N3e5Jeo";
export const TNC_PATH = "/tnc";
export const PRIVACY_POLICY_PATH = "/privacy";