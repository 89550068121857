import { useState, useEffect } from "react";

const minWidthForMobileView = 769;

const useDynamicDimension = (url) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
    isMobileView: window.innerWidth < minWidthForMobileView
  });

const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
      isMobileView: window.innerWidth < minWidthForMobileView
    })
  }


  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
        window.removeEventListener('resize', setDimension);
    });

  }, [screenSize]);

  return screenSize;
};

export default useDynamicDimension;
