import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLoaderData,
  useLocation
} from "react-router-dom";
import Cards from "../components/Cards";
import NavBar from "../components/Navigation/NavBar";
import { decrement, increment, incrementByAmount } from "../redux/slices/counterSlice";
import { getPaymentRequestStatus } from "../redux/slices/paymentSlice";
import { resetUserListAction } from "../redux/slices/usrSlice";

import { useCallback, useEffect, useState } from "react";
import {searchAction, usrAction} from "../redux/slices/usrSlice";
import CustomSearch from "../components/CustomSearch";
import { encryptPassword, getConstant, getGeoLocation, handleHeader } from "../services/functions";
import { SUBSCRIPTION_DETAILS_PATH } from "../utility/path";



function Home() {
  //const { users } = useLoaderData();
  
  const usersData = useSelector(state => state?.users?.data?.user);
  const usersLoading = useSelector(state => state?.users?.loading);
  const skipData = useSelector(state => state?.users?.data?.skip);
  const limitData = useSelector(state => state?.users?.data?.limit);
  const counter = useSelector(state => state?.counter);
  const categoriesData = useSelector(state => state?.categories?.data);
  const authData = useSelector(state => state?.auth?.data);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(skipData);
  const [limit, setLimit] = useState(limitData);
  const [selectedCategory, setSelectedCategory] = useState();

  const dispatch = useDispatch();

  const handleClick = e =>{
    setSelectedCategory((current)=> {return e?.id});
    setSkip((current)=> {return 0});
    setUsers((current)=>{return []});
    
    if(!e?.name){
      dispatch(usrAction({skip:0,limit}));
    }else{
      dispatch(searchAction({selectedCategory:e?.id,skip:0,limit}));
    }
    
  }

  const handleUsersUpdate = useCallback((newUsers) => {
    if(newUsers?.length){
      setUsers((currentUsers) => [...currentUsers, ...newUsers]);
    }
    
  }, []);

  useEffect(() => {
    handleUsersUpdate(usersData);
    setSkip(skipData);
    setLimit(limitData);
    return () => {}
  }, [usersData]);

  useEffect(()=>{
    const txnId = searchParams && searchParams.get("txnId");
    const plan = searchParams && searchParams.get("plan");
    if(txnId){
      dispatch(getPaymentRequestStatus({ txnId, id: authData?.id, type: "transaction",plan }));
      navigate(SUBSCRIPTION_DETAILS_PATH);
    }else{
      dispatch(usrAction({skip:0,limit:5}));
      handleHeader({...getConstant("HOME")},dispatch);
    }
    
    return () => { setSkip(0);setLimit(5);setUsers([]); dispatch(resetUserListAction({})) }
  },[])

  return (
    <>
    <NavBar/>
    <div className="main-container">
      <div className="heading">
        <h1 className="heading__title">Gaufi</h1>
        <CustomSearch categoriesData={categoriesData} handleClick={handleClick}/>
      </div>
      
      {users?.length > 0  ? (
        <>
          <Cards users={users}/>

          {
          limit > 0 && !selectedCategory ? (<div className="cards_ct">
            <button className="btn btn-outline-primary btn-sm m-3" disabled={usersLoading} onClick={(e)=>{e.preventDefault();dispatch(usrAction({skip,limit}))}}>{usersLoading === true ? 'loadin..' : 'View more'}</button>
          </div>) : ""
          }
          {
          limit > 0 && selectedCategory ? (<div className="cards_ct">
            <button className="btn btn-outline-primary btn-sm m-3" disabled={usersLoading} onClick={(e)=> {e.preventDefault();dispatch(searchAction({selectedCategory,skip,limit}))}}>{usersLoading === true ? 'loadin..' : 'View more'}</button>
          </div>) : ""
          }
        </>
      ) : ''}

      {/* {usersLoading === true ? ( <div className="cards_ct">Loading..</div>) : ''} */}
      {/* {users?.length === 0 && usersLoading === false ? ( <div className="cards_ct">No record found!!</div>) : ''} */}
      
    </div>
    </>
  );
}

export default Home;

// export async function loaderUsers() {
//   const users = await getUsers();
//   return { users };
// }

export async function loaderLocation() {
  const position = await getGeoLocation();
  const data = {
      latitude:position.coords.latitude,
      longitude:position.coords.longitude,
      altitude:position.coords.altitude,
      accuracy:position.coords.accuracy,
      altitudeAccuracy:position.coords.altitudeAccuracy,
      heading:position.coords.heading,
      speed:position.coords.speed,
      timestamp:position.timestamp
  }
  return data;
}
