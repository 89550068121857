import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import details from '../../mock/details.json';
import axios from "axios";
import {BASE_URL} from "../../utility/path";
import { spinner } from "./spinnerSlice";
import { getConfig, handleErrorResponse } from "../../services/functions";

//verify mobile action
export const detailsAction = createAsyncThunk(
    "users/details",
    async (user, { rejectWithValue, getState, dispatch }) => {
      dispatch(spinner(true));
      
      const config = getConfig();
      
      try {
        const { data } = await axios.get(
          `${BASE_URL}/api/users/details?otherUserId=${user.otherUserId}`,
          config
        );
  
        dispatch(spinner(false));
        return data;

      } catch (error) {
        dispatch(spinner(false));
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }

    }
);

export const updateDetailsAction = createAsyncThunk(
  "users/updateDetails",
  async (user, { rejectWithValue, getState, dispatch }) => {
    
    try {
    
      //console.log("users/updateDetails",user);
      dispatch(spinner(false));
      return user;

    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }

  }
);
  
//slices
const detailsSlices = createSlice({
  name: "details",
  initialState: {
    data: {},
  },
  extraReducers: builder => {
    //verify mobile
    builder.addCase(detailsAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(detailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(detailsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });


    builder.addCase(updateDetailsAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateDetailsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});
  
export default detailsSlices.reducer;
  
