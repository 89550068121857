import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../utility/path";
import { callGetGeoLocation } from "../../services/functions";

// action
export const locationSaveAction = createAsyncThunk(
    "location/save",
    async (data, { rejectWithValue, getState, dispatch }) => {
      let location = {latitude:-1,longitude:-1};
      try {
        await callGetGeoLocation();

        location = localStorage.getItem("location")
        ? JSON.parse(localStorage.getItem("location"))
        : {latitude:-1,longitude:-1};

      } catch (error) {
        console.log(error);
      }

      return location;
    }
);

export const locationClearAction = createAsyncThunk(
  "location/clear",
  async (data, { rejectWithValue, getState, dispatch }) => {
    localStorage.setItem("location",JSON.stringify({latitude:-1,longitude:-1}));
    return {latitude:-1,longitude:-1};
  }
);

//slices
const locationSlice = createSlice({
  name: "location",
  initialState: {
    data: {latitude:-1,longitude:-1},
  },
  extraReducers: builder => {
    //position
    builder.addCase(locationSaveAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(locationSaveAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(locationSaveAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    builder.addCase(locationClearAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(locationClearAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(locationClearAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default locationSlice.reducer;
