import { useEffect, useState } from "react";
import CircleWithAlphabet from "./CircleWithAlphabet";

const CustomImage = ({state, className, size}) => {
  
  const [defaultSrc] = useState('');
  const [newSrc, setNewSrc] = useState();
  const [initialLetter,setInitialLetter] = useState(state?.name?.charAt(0));
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setNewSrc(state?.image);
    setInitialLetter(state?.name?.charAt(0));
    
  }, [state?.image]);

  return (
    <>
    {
     !imageError ? (
     <img
       src={newSrc}
       className={className}
       onError={(e) => {
         //e.target.src = defaultSrc;
         //console.log("err in image")
         setImageError(true);
       }}
     />) : ''}
     { imageError ? (<CircleWithAlphabet letter={initialLetter || "Hi!!"} size={size} color={"linear-gradient(to bottom,#dee2e6,#495057)"} />) : ''}
    </>
  );
}

export default CustomImage;
