import { useEffect, useState } from "react";
import { ABOUT_US_PATH, CATEGORY_PATH, CONTACT_US_PATH, HOME_PATH, LOGOUT_PATH, MANAGE_NOTIFICATION_PATH, NOTIFCATION_PATH, PROFILE_PATH, PUSH_NOTIFICATION_PATH, REGISTER_PATH, SUBSCRIPTION_PATH, MANAGE_SUBSCRIPTION_PLAN_PATH, TNC_PATH, REGISTER_GENERAL_PATH } from "../../utility/path";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "../../navbar.css";
import { logoutAction } from "../../redux/slices/authSlice";
import { logoutErrors } from "../../services/functions";
import useDynamicDimension from "../../services/useDynamicDimension";
import CustomIcon from "../CustomIcon";
import CustomImage from "../CustomImage";
import { hideAlert } from "../../redux/slices/alertSlice";
import logo from '../../logo.png';



const NavBar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const authData = useSelector(state => state?.auth?.data);
  const header = useSelector(state => state?.header?.data);
  const alert = useSelector(state => state?.alert);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = useDynamicDimension();

  //console.log('screenSize',location?.pathname,location?.pathname.indexOf('register'),authData?.nextStep,!authData?.isLoggedIn,location?.pathname.indexOf('login') < 0);



  // if (authData?.nextStep > 1 && !authData?.isLoggedIn && location?.pathname.indexOf('register') < 0) {
  //   if (location?.pathname.indexOf(LOGIN_PATH) < 0 && location?.pathname.indexOf(FORGOT_PWD_PATH) < 0) {
  //     dispatch(logoutAction({ source: "navbar" }));
  //   }
  // }


  const hideBack = location?.pathname == "/";

  //console.log(window.isMobileView);

  //note: dont add login, because it's impact forgor pwd
  const logoutRequiredFor = ["logout"];

  const handleClick = (param) => (e) => {
    e.preventDefault();

    if (logoutRequiredFor.indexOf(param) > -1) {
      dispatch(logoutAction({ source: "handleClick" }));
    }

    if(param == "registervendor"){
      dispatch(logoutAction({ source: "handleClick" }));
    }

    if (param === "home") {
      return navigate(HOME_PATH);
    }

    if (param === "notification") {
      return navigate(NOTIFCATION_PATH);
    }
    
    setIsNavExpanded(!isNavExpanded);
  }

  useEffect(() => {
    if (logoutErrors.includes(alert?.data?.message)) {
      //console.log('token error');
      dispatch(logoutAction({}));
      setTimeout(() => {
        dispatch(hideAlert({}));
        navigate(REGISTER_PATH);
      }, 1500)
    }
  }, [alert])

  return (
    <nav className="navigation justify-content-between" id="header">
      {!hideBack && screenSize.isMobileView ?
        (<div>
          <a className="heading__link" onClick={(e) => { e.preventDefault(); navigate(header.gotoAction) }}>
            <CustomIcon type="chevron-left" />
            <span className="vertical_align_middle">{header.gotoName}</span>
          </a>
        </div>) : <div>&nbsp;</div>
      }

      {/* Center header text */}
      {!hideBack && screenSize.isMobileView ?
        <div>{header?.currentPageName}</div> : <div>&nbsp;</div>
      }

      {/* Profile icon header */}
      {
        screenSize.isMobileView && authData?.isLoggedIn ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
             <div className="heading__link" onClick={handleClick("notification")}>
              <div className="notification-bell">
                <svg className="notification-bell-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <CustomIcon type="bell"/>
                </svg>
                <span className="notification-dot"></span>
              </div>
             </div>

              <div className="heading__link" onClick={handleClick("toggle")}>
                <CustomImage className="thumbnail circle" state={{
                  image: authData?.profilePhoto,
                  name: authData?.name
                }} size={"2.7rem"} />
            </div>
          </div>
          ) :
          ''
      }

      {screenSize.isMobileView && !authData?.isLoggedIn ? (<div>
              <button  className="border-0 bg-transparent" onClick={handleClick("toggle")}><img src={logo} alt="Logo" height="45" width="45" className="circle"></img></button>
            </div>) : ''}

      {!screenSize.isMobileView && !authData?.isLoggedIn ? (<div>
      </div>) : ''}

      <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul className="list-scrollable" style={{maxHeight: '92vh'}}>
          <li onClick={handleClick("home")}>
            <NavLink to={HOME_PATH}>
              Home
            </NavLink>
          </li>

          {/* {!authData?.isLoggedIn ? (
            <li onClick={handleClick("login")}>
              <NavLink to={LOGIN_PATH}>
                Login
              </NavLink>
            </li>) : ''} */}

          {!authData?.isLoggedIn ? (
            <li onClick={handleClick("register")}>
              <NavLink to={REGISTER_PATH}>
                Register/ Login
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("registervendor")}>
              <NavLink to={REGISTER_GENERAL_PATH}>
                Register Vendor
              </NavLink>
            </li>) : ''}

          {authData?.isLoggedIn ? (
            <li onClick={handleClick("profile")}>
              <NavLink to={PROFILE_PATH}>
                Profile
              </NavLink>
            </li>) : ''}
  
          {authData?.isLoggedIn && authData?.role === "producer" ? (
            <li onClick={handleClick("subscription")}>
              <NavLink to={SUBSCRIPTION_PATH}>
                Subscription
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("category")}>
              <NavLink to={CATEGORY_PATH}>
                Category
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("pushnotification")}>
              <NavLink to={PUSH_NOTIFICATION_PATH}>
                Push Notification
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("managesubscriptionplan")}>
              <NavLink to={MANAGE_SUBSCRIPTION_PLAN_PATH}>
                Manage Subscription Plan
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("managenotification")}>
              <NavLink to={MANAGE_NOTIFICATION_PATH}>
                Manage Notifications
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn ? (
            <li onClick={handleClick("notification")}>
              <NavLink to={NOTIFCATION_PATH}>
                Notifications
              </NavLink>
            </li>) : ''}

          <li onClick={handleClick("contactus")}>
            <NavLink to={CONTACT_US_PATH}>
              Contact Us
            </NavLink>
          </li>

          <li onClick={handleClick("aboutus")}>
            <NavLink to={ABOUT_US_PATH}>
              About Us
            </NavLink>
          </li>

          <li onClick={handleClick("tnc")}>
            <NavLink to={TNC_PATH}>
              Terms & Conditions
            </NavLink>
          </li>

          {authData?.isLoggedIn ? (
            <li onClick={handleClick("logout")}>
              <NavLink to={LOGOUT_PATH}>
                Logout
              </NavLink>
            </li>) : ''}
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
