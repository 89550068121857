import { useDispatch, useSelector } from "react-redux";
import {
    useNavigate
} from "react-router-dom";
import { getDistanceFromLatLonInKm, runAnalytic } from "../services/functions";
import {REGISTER_PATH } from "../utility/path";
import CustomImage from "./CustomImage";
import ListItem from "./ListItem";
import { locationSaveAction } from "../redux/slices/locationSlice";
import { useEffect, useState } from "react";


// const location = localStorage.getItem("location")
// ? JSON.parse(localStorage.getItem("location"))
// : {latitude:-1,longitude:-1};

const Card = ({ idx, user }) => {

    const authData = useSelector(state => state?.auth?.data);
    const location = useSelector(state => state?.location?.data || { latitude: -1, longitude: -1 });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ratingByOthersData = user?.ratingByOthers || [];
    const ratingByOthersGroupByData = groupBy(ratingByOthersData, rating => rating.rating);

    const averageRating = (1 * ratingByOthersGroupByData?.get(1)?.length +
        2 * ratingByOthersGroupByData?.get(2)?.length +
        3 * ratingByOthersGroupByData?.get(3)?.length +
        4 * ratingByOthersGroupByData?.get(4)?.length +
        5 * ratingByOthersGroupByData?.get(5)?.length) / ratingByOthersData?.length || 0;

    const averageRatingRound = Math.round(averageRating * 10) / 10;


    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const handleClick = input => e => {
        e.preventDefault();
        console.log("click", input, user.category[0].name, user.id)
        if (authData?.isLoggedIn || true) {
            runAnalytic(user.id, authData, dispatch, 1, user.id, user?.category[0]?.id);
            return navigate(input)
        }
        return navigate(REGISTER_PATH);
    }

    const getCardColorIdx = (idx) => {
        return idx % 6;
    }

    const handleClick_CheckDistance = () => {
        console.log('check distance')
        dispatch(locationSaveAction({}))
    }

    const [showTransition, setShowTransition] = useState(false);
    useEffect(() => {
        setShowTransition(true);

        return () => { setShowTransition(false) }
    }, []);

    return (
        <>
            {user ? (<div className={`card_ct card-${getCardColorIdx(idx)} show-transition ${showTransition ? "show" : ""}`} onClick={handleClick(`/details/${user.id}`)}>
                <div className="card__icon d-flex" >
                    <CustomImage state={{ image: user?.profilePhoto, name: user?.name }} className="thumbnail circle" size={"3rem"} />
                    <div className="p-1">
                        {user?.name}
                    </div>
                </div>

                {/* <small className="card__fee">{user?.fee ? "Service Charge* " + user?.fee + " INR" : ""}</small> */}
                <small className="card__fee">&nbsp;</small>

                <div className="card__title">{user?.category[0]?.name}</div>

                <ul className="card__ratings p-0">
                    {location?.latitude > -1 && user?.location?.coordinates?.length > 0 ? 
                        <ListItem value={getDistanceFromLatLonInKm(location, user) + " km away"} /> :
                        // <ListItem value={"Click here to see distance"} handleClick={handleClick_CheckDistance} />
                        ''
                    }
                        
                    {ratingByOthersData?.length > 0 && <ListItem value={ratingByOthersData?.length + " Ratings"} />}
                    {user?.commentByOthers?.length > 0 && <ListItem value={user?.commentByOthers?.length + " Reviews"} />}
                    {user?.followers?.length > 0 && <ListItem value={user?.followers?.length + " Followers"} />}
                </ul>

                <p className="card__apply">
                    {/* <Link className="card__link" to={`/details/${user.id}`}>Details</Link> */}
                    {/* <span className="card__link" onClick={handleClick(`/details/${user.id}`)}>Contact</span> */}
                    <span className="card__link">Contact</span>
                </p>
            </div>) : 'loading..'}
        </>
    );
}

export default Card;
