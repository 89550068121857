import {createAction, createReducer} from '@reduxjs/toolkit';

export const spinner = createAction('spinner/action');

// using nuilder notation
const initialState = {
    value :false,
}

export const spinnerSlice = createReducer(initialState,
    builder => {
    builder.addCase(spinner,(state,action)=>{
        //console.log("spinner,",action.payload)
        state.value = action.payload;
    });
});
