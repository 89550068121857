import { useState } from "react";
import ListItem from "./ListItem";
import { useDispatch, useSelector } from "react-redux";
import { addAnalyticAction } from "../redux/slices/analyticSlice";
import { getDeviceId, runAnalytic } from "../services/functions";

const CustomSearch = ({categoriesData,handleClick}) => {

    const dispatch = useDispatch();
    const authData = useSelector(state => state?.auth?.data);

    const [filteredCategories,setFilteredCategories] = useState([]);
    const [enteredValue,setEnteredValue] = useState("");
    const [selectedCategory,setSelectedCategory] = useState("");
    
    
    const handleChange = () => e => {
        e.preventDefault();

        //analycis
        runAnalytic(e?.target?.value, authData,dispatch,0,null,null);
        
        setEnteredValue(e.target.value);
        
        const temp = categoriesData?.filter((c)=>{
            if(c.name.toLowerCase()!='user'){ // skipping user category
                return c?.name.indexOf(e.target.value.toLowerCase()) > -1
            }
        });

        setFilteredCategories(temp);
    }

    const _handleClick = e => (event) =>{
      event.preventDefault();
      console.log(e);
      runAnalytic(e?.name, authData,dispatch,0,null,e?.id);
      setSelectedCategory(e?.name);
      handleClear();
      handleClick(e);
    }

    const handleClear = () =>{
        document.getElementById("searchId").value="";
        document.getElementById("searchId").focus();
        setEnteredValue("");
    }
    
      
    return (
        <>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="form-group input-group mb-3">
                        <input id={"searchId"} type={"text"} className="form-control custom-search-input"
                            placeholder={"search electrician, mechanic, etc.."}
                            onChange={handleChange("search")} 
                            value={enteredValue} 
                            autoComplete="off"
                         
                            />
                         
                        {enteredValue && <div className="clear_outer">
                            <span className="clear_inner" id={enteredValue+"searchId"} 
                            onClick={handleClear}>&times;</span>
                        </div>}

                        {enteredValue && (
                            <div className="col-md-6 offset-md-3 filtered-box" id="searchDiv">
                                <ul className="list-group list-scrollable">
                                    {
                                    filteredCategories.map(f=>{
                                        return (<li key={f.id} onClick={_handleClick(f)} 
                                        className="list-group-item capitalize">{f.name}</li>)
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    {selectedCategory && <ul className="card__ratings p-0">
                        <ListItem value={selectedCategory} clear={true} handleClick={_handleClick}/>
                    </ul>}
                </div>
            </div>
        </>
    );
  }
  
  export default CustomSearch;
  
