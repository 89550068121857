import { useState } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomIcon from "./CustomIcon";

const CustomTooltip = ({tooltipInfo}) => {
    return (
        <>
        {tooltipInfo ? (<OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-top">
            {tooltipInfo}
          </Tooltip>
        }
      >
        <a><CustomIcon type={"info"}/></a>
      </OverlayTrigger>) : ''}
      </>
    );
  }
  
  export default CustomTooltip;