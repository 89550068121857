import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../components/CustomInput";
import NavBar from "../components/Navigation/NavBar";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomCheckbox from "../components/CustomCheckbox";
import { sendNotificationAction } from "../redux/slices/notificationSlice";
import { resetUserListAction, usrAction } from "../redux/slices/usrSlice";
import MainContainer from "../components/MainContainer";
import { getConstant, handleHeader } from "../services/functions";

const PushNotification = ({ }) => {
    const usersData = useSelector(state => state?.users?.data?.user);
    const skipData = useSelector(state => state?.users?.data?.skip);
    const limitData = useSelector(state => state?.users?.data?.limit);
    const usersLoading = useSelector(state => state?.users?.loading);
    const authData = useSelector(state => state?.auth?.data);

    const [users, setUsers] = useState([]);
    const [skip, setSkip] = useState(skipData);
    const [limit, setLimit] = useState(limitData);

    const dispatch = useDispatch();

    const handleUsersUpdate = useCallback((newUsers) => {
        if (newUsers?.length) {
            setUsers((currentUsers) => [...currentUsers, ...newUsers]);
        }
    }, []);

    useEffect(() => {
        handleUsersUpdate(usersData);
        setSkip(skipData);
        setLimit(limitData);

        return () => { }
    }, [usersData]);

    useEffect(() => {
        dispatch(usrAction({ skip: 0, limit: 5 }));
        handleHeader({...getConstant("PUSH_NOTIFICATION")},dispatch);

        return () => { setSkip(0); setLimit(5); setUsers([]); dispatch(resetUserListAction({})) }
    }, [])


    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            title: '',
            content: '',
            openUrl: '',
            ids: [],
        },
        validationSchema: Yup.object({
            title: Yup.string()
                .max(50, 'Must be 50 characters or less')
                .required('Required'),
            content: Yup.string()
                .max(50, 'Must be 20 characters or less')
                .required('Required'),
            openUrl: Yup.string()
                .max(50, 'Must be 50 characters or less'),
            ids: Yup.array().min(1, 'Select atleast one user'),
        }),
        onSubmit: values => {
            //alert(JSON.stringify(values, null, 2));
            dispatch(sendNotificationAction(JSON.stringify({ ...values, user: authData?.id }, null, 2)));
            //formik.values.ids.forEach(id=>setFieldValue("ids",false));
            reset();
        }
    });

    const reset = () => {
        console.log("reset");
        //formik.resetForm();
        // var inputs = document.querySelectorAll('.ids');
        // for (var i = 0; i < inputs.length; i++) {
        //     inputs[i].checked = false;
        // }
        formik.handleReset();
    }

    const renderForm = () => {
        return (
            <>
                <form onSubmit={formik.handleSubmit}>
                    <CustomInput title="Title" id="title" name="title" type="text"
                        handleChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.title} formikError={true} formik={formik} />

                    <CustomInput title="Content" id="content" name="content" type="text"
                        handleChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.content} formikError={true} formik={formik} />

                    <CustomInput title="Url" id="openUrl" name="openUrl" type="text" placeholder="(optional)"
                        handleChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.openUrl} formikError={true} formik={formik} />

                    <div className="mb-3">
                        <h3>Select users {users.length}</h3>
                        <ul className="card__ratings p-0 m-0">
                            {
                                users?.length > 0 && users.map((user, index) => (
                                    <CustomCheckbox key={index} name="ids" onChange={formik.handleChange} value={user.id}
                                        id={user.id} label={user.name} validate={user?.notificationEnabled} formikError={true} formik={formik} />
                                ))
                            }
                        </ul>
                        {formik.touched.ids && !formik.values.ids.length ? <div className="text-danger">{formik.errors.ids}</div> : null}
                    </div>
                    <div>
                        {
                            limit > 0 ? (<div className="cards_ct">
                                <button className="btn btn-primary btn-sm m-3" disabled={usersLoading} onClick={(e) => { e.preventDefault(); dispatch(usrAction({ skip, limit })) }}>View more</button>
                            </div>) : ""
                        }
                    </div>
                    <button type="submit" className="btn btn-outline-primary">Submit</button>
                    {/* <button className="btn btn-outline-primary" onClick={reset}>Reset</button> */}

                </form>
            </>
        );
    }

    return (
        <>
            <NavBar />
            <MainContainer>
                {renderForm()}
            </MainContainer>
        </>
    );
}

export default PushNotification;
