import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import categories from '../../mock/categories.json';
import {BASE_URL} from "../../utility/path";
import axios from "axios";
import {splash} from "./splashSlice";
import { spinner } from "./spinnerSlice";
import { getConfig, handleErrorResponse } from "../../services/functions";
import { showAlert } from "./alertSlice";

//verify action
export const listCategoryAction = createAsyncThunk(
    "categories/list",
    async (user, { rejectWithValue, getState, dispatch }) => {
      
      const config = getConfig();

      try {
        const { data } = await axios.get(
          `${BASE_URL}/api/categories/list`,
          config
        );
        
        //dispatch(splash(false));
        
        return data;

      } catch (error) {
        dispatch(splash(false));
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }

      //return categories;
    }
);

//verify action
export const addCategoryAction = createAsyncThunk(
  "categories/add",
  async (rq, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = getConfig();

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/categories/add`,
        rq,
        config
      );

      let catgories = Object.assign([], getState()?.categories?.data);
      catgories.push(data);
      dispatch(spinner(false));
      dispatch(showAlert({ message: data.message, success: true }));
      return catgories;

    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }

    //return categories;
  }
);
  
//slices
const categoriesSlices = createSlice({
  name: "categories",
  initialState: {
    data: [],
  },
  extraReducers: builder => {
    //list
    builder.addCase(listCategoryAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(listCategoryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(listCategoryAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //add
    builder.addCase(addCategoryAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addCategoryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addCategoryAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});
  
export default categoriesSlices.reducer;
  
