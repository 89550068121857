import { useState } from "react";
import { useSelector } from "react-redux";
import Card from "../components/Card";

const Cards = ({users}) => {
  let idx = 1;  
    return (
      <div className="cards_ct">
          {
          users.map((user)=>(
            <Card idx={idx++} key={user.id} user={user}/>
        ))}
      </div>
    );
  }
  
  export default Cards;
  
