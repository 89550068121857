import { useState } from "react";
import "../rating.css";


const StarRating = ({handleSubmit}) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const getCssClass = (index) => {
        let temp = index <= ((rating || hover)) ? "checked" : "off";
        return "fa fa-star " + temp;
    }

    const callHandleSubmit = e =>{
        e.preventDefault();
        console.log("test");
        setRating(0);
        setHover(0);
    }

    // const handleSubmit = (type) => e => {
    //     e.preventDefault();
    //     console.log("rating", rating, "hover", hover);

    // }

    return (
        <>
            <h3>Ratings</h3>
            <div className="mb-3 star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            key={index}
                            className="rating-button"
                            onClick={() => { setHover(index); setRating(index); }}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                            onDoubleClick={() => {
                                setRating(rating - 1);
                                setHover(hover - 1);
                                // console.log(rating,hover);
                            }}
                        >
                            {/* <span className="star">&#9733;</span> */}
                            <span className={getCssClass(index)}></span>
                        </button>
                    );
                })}

            </div>
            {rating > 0 ? (<button className="btn btn-outline-primary mb-3" 
            onClick={handleSubmit("rating",rating)} disabled={rating<1}>Post ratings</button>) : ''}
        </>
    );
};

export default StarRating;
