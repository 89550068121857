import React, { useState } from 'react';

const SumVerification = ({ onVerify }) => {
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [userInput, setUserInput] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleVerify = () => {
    const sum = num1 + num2;
    if (parseInt(userInput, 10) === sum) {
      setIsCorrect(true);
      if (onVerify) onVerify(true);
    } else {
      setIsCorrect(false);
      if (onVerify) onVerify(false);
    }
  };

  const handleRetry = () => {
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setUserInput('');
    setIsCorrect(null);
  };

  return (
    <>
      <div className="input-group mb-3">
        <span className="form-control otpInput">{num1}</span> +&nbsp;&nbsp; <span className="form-control otpInput">{num2}</span> =&nbsp;&nbsp;{' '}
        <input
          type="number"
          value={userInput}
          onChange={handleInputChange}
          placeholder="Enter sum"
          className="form-control otpInput"
        />
      </div>
      <button className='btn btn-primary' onClick={handleVerify} disabled={!userInput}>Verify</button>
      {isCorrect === true && (
        <div style={{ color: 'green' }}>Correct! 🎉</div>
      )}
      {isCorrect === false && (
        <div style={{ color: 'red' }}>Incorrect, try again.</div>
      )}
      {(isCorrect === true || isCorrect === false) && (
        <button className='btn btn-primary' onClick={handleRetry}>Try Another</button>
      )}
    </>
  );
};

export default SumVerification;
