import React, { useEffect, useState } from "react";

const MainContainer = ({ children, text }) => {
    const [showTransition, setShowTransition] = useState(false);

    useEffect(() => {
        setShowTransition(true);

        return () => {
            setShowTransition(false);
        };
    }, []);

    return (
        <>
            <div className={`main-container show-transition ${showTransition ? "show" : ""}`}>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        {children ? children : text}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainContainer;
