import React from 'react';

const CircleWithAlphabet = ({ letter, size, color }) => {
  const circleStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    background: color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    color: 'white',
    textTransform: 'capitalize',
  };

  return (
    <div style={circleStyle}>
      {letter}
    </div>
  );
};

export default CircleWithAlphabet;
