import {createAction, createReducer} from '@reduxjs/toolkit';

export const splash = createAction('splash/save');


// using nuilder notation
const initialState = {
    value :true,
}

export const splashSlice = createReducer(initialState,
    builder => {
    builder.addCase(splash,(state,action)=>{
        state.value = action.payload;
    });
});
