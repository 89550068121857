import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Category from "./pages/Category";

import Details from "./pages/Details";
import Home,{loaderLocation} from "./pages/Home";
import Profile from "./pages/Profile";
import PushNotification from "./pages/PushNotification";
import Register from "./pages/Register";
import Spinner from "./pages/Spinner";
import Splash from "./pages/Splash";
import Subscription from "./pages/Subscription";
import { logoutAction} from "./redux/slices/authSlice";
import { listCategoryAction } from "./redux/slices/categoriesSlice";
import { usrAction} from "./redux/slices/usrSlice";


import {HOME_PATH,REGISTER_PATH,DETAILS_PATH, PROFILE_PATH, CATEGORY_PATH, LOGOUT_PATH, FORGOT_PWD_PATH, PUSH_NOTIFICATION_PATH, SUBSCRIPTION_PATH, CHANGE_PASSWORD_PATH, CHANGE_IMAGE_PATH, CHANGE_SERVICE_PATH, CHANGE_ADDRESS_PATH, SUBSCRIPTION_DETAILS_PATH, ABOUT_US_PATH, CONTACT_US_PATH, NOTIFCATION_PATH, REGISTER_GENERAL_PATH, MANAGE_SUBSCRIPTION_PLAN_PATH, MANAGE_NOTIFICATION_PATH, TNC_PATH, PRIVACY_POLICY_PATH} from "./utility/path";
import ChangePassword from "./pages/ChangePassword";
import ChangeImage from "./pages/ChangeImage";
import ChangeService from "./pages/ChangeService";
import ChangeAddress from "./pages/ChangeAddress";
import { setHeader } from "./redux/slices/headerSlice";
import SubscriptionDetails from "./pages/SubscriptionDetails";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import TnC from "./pages/TnC";
import Notification from "./pages/Notification";
import ManageSubscriptionPlan from "./pages/ManageSubscriptionPlan";
import ManageNotification from "./pages/ManageNotification";
import PrivacyPolicy from "./pages/PrivacyPolicy";


function App() {
  const dispatch = useDispatch();
  const splash = useSelector(state => state?.splash?.value);
  const spinner = useSelector(state => state?.spinner?.value);
  
  const router = createBrowserRouter([
    {
      path: HOME_PATH,
      element: <Home/>,
    //  loader: ()=>{
    //   //loaderLocation();
    //   //dispatch(usrAction({}));
    //   //dispatch(listCategoryAction({}));
    //   return null;
    //   ;
    //  }
    },
    // {
    //   path: LOGIN_PATH,
    //   element: <Register/>,
    // },
    {
      path: REGISTER_PATH,
      element: <Register/>,
      loader: ()=>{
        //localStorage.removeItem("userInfo");
        //localStorage.removeItem("location");
        //dispatch(logoutAction({}));
        return {vendor:false};
      }
    },
    {
      path: REGISTER_GENERAL_PATH,
      element: <Register/>,
      loader: ()=>{
        //localStorage.removeItem("userInfo");
        //localStorage.removeItem("location");
        //dispatch(logoutAction({}));
        return {vendor:true};
      }
    },
    {
      path: DETAILS_PATH,
      element: <Details/>,
    },
    {
      path: PROFILE_PATH,
      element: <Profile/>,
    },
    {
      path: CATEGORY_PATH,
      element: <Category/>,
    },
    {
      path: PUSH_NOTIFICATION_PATH,
      element: <PushNotification/>,
    },
    {
      path: SUBSCRIPTION_PATH,
      element: <Subscription/>,
    },
    {
      path: MANAGE_SUBSCRIPTION_PLAN_PATH,
      element: <ManageSubscriptionPlan/>,
    },
    {
      path: MANAGE_NOTIFICATION_PATH,
      element: <ManageNotification/>,
    },
    {
      path: LOGOUT_PATH,
      element: <Register/>,
      loader: ()=>{
        return null;
      }
    },{
      path: FORGOT_PWD_PATH,
      element: <Register/>,
      loader: ()=>{
        return null;
      }
    },{
      path: CHANGE_ADDRESS_PATH,element: <ChangeAddress/>,loader: ()=>{return dispatch(setHeader({gotoName:"Profile",gotoAction:PROFILE_PATH}));null;}
    },{
      path: CHANGE_PASSWORD_PATH,element: <ChangePassword/>,loader: ()=>{return dispatch(setHeader({gotoName:"Profile",gotoAction:PROFILE_PATH}));null;}
    },{
      path: CHANGE_IMAGE_PATH,element: <ChangeImage/>,loader: ()=>{return dispatch(setHeader({gotoName:"Profile",gotoAction:PROFILE_PATH}));null;}
    },{
      path: CHANGE_SERVICE_PATH,element: <ChangeService/>,loader: ()=>{return dispatch(setHeader({gotoName:"Profile",gotoAction:PROFILE_PATH}));null;}
    },{
      path: SUBSCRIPTION_DETAILS_PATH,element: <SubscriptionDetails/>,loader: ()=>{return dispatch(setHeader({gotoName:"Subscription",gotoAction:SUBSCRIPTION_PATH}));null;}
    },{
      path: CONTACT_US_PATH,element: <ContactUs/>,loader: ()=>{return null;}
    },{
      path: ABOUT_US_PATH,element: <AboutUs/>,loader: ()=>{return null;}
    },{
      path: TNC_PATH,element: <TnC/>,loader: ()=>{return null;}
    },{
      path: NOTIFCATION_PATH,element: <Notification/>,loader: ()=>{return null;}
    },{
      path: PRIVACY_POLICY_PATH,element: <PrivacyPolicy/>,loader: ()=>{return null;}
    }
  ]);

  return (
    <>
      {splash ? <Splash/> : (<RouterProvider router={router} />) }
      {spinner ? <Spinner/> : ""}
      
    </>
    
  );
} 

export default App;
