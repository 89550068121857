import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Provider, useDispatch} from 'react-redux';
import store from './redux/store/store';

import {getDeviceId, getGeoLocation, onresize} from './services/functions';
import Spinner from './pages/Spinner';
import Alert from './components/Alert';
import Main from './Main';

const root = ReactDOM.createRoot(document.getElementById('root'));

const callGetGeoLocation = async () => {
  console.log('callGetGeoLocation')
  try{
    const position = await getGeoLocation();
    const data = {
      latitude:position.coords.latitude,
      longitude:position.coords.longitude,
      altitude:position.coords.altitude,
      accuracy:position.coords.accuracy,
      altitudeAccuracy:position.coords.altitudeAccuracy,
      heading:position.coords.heading,
      speed:position.coords.speed,
      timestamp:position.timestamp
    }
    localStorage.setItem("location",JSON.stringify(data));
    renderReactDom();
  }catch(error){
      console.log("callGetGeoLocation,error, ",error);
      localStorage.setItem("location",JSON.stringify({latitude:-1,longitude:-1}));
      renderReactDom();
  }
}

const renderReactDom = async () => {
  root.render(
  <Provider store={store}>
      <Main />
  </Provider>
);
};

const registerServiceWorker = async () =>{
  console.log("registerServiceWorker");
  if('serviceWorker' in navigator){
    console.log('service worker is available')
    navigator.serviceWorker.register('/sw.js')
    .then(function(){
      console.log('service worker registered')
    });
  }
}

if (window.cordova) {
  console.log('device is ready');
  document.addEventListener('deviceready', () => {
    //callGetGeoLocation();
    //renderReactDom();
    //window.addEventListener("resize", onresize); 
  }, false);
} else {
  //callGetGeoLocation();
  //renderReactDom();
  //window.addEventListener("resize", onresize); 
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
callGetGeoLocation();
reportWebVitals();
registerServiceWorker();
getDeviceId();