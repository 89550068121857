import { useState } from "react";
import "../rating.css";

const Rating = ({ ratingByOthersGroupByData, ratingByOthersData }) => {
    const ratingCount = 100;
    //console.log("ratingByOthersGroupByData",ratingByOthersGroupByData?.get(5)?.length || 0)

    //AR = 1*a+2*b+3*c+4*d+5*e/(R)

    const averageRating = (1*ratingByOthersGroupByData?.get(1)?.length + 
    2*ratingByOthersGroupByData?.get(2)?.length + 
    3*ratingByOthersGroupByData?.get(3)?.length +
    4*ratingByOthersGroupByData?.get(4)?.length +
    5*ratingByOthersGroupByData?.get(5)?.length)/ ratingByOthersData?.length || 0;

    const averageRatingRound = Math.round(averageRating * 10) /10;


    const checkedStar = Math.round(averageRatingRound);
    const uncheckedStar = 5 - checkedStar;


    return (
        <>
        {averageRatingRound && averageRatingRound > 0 ? <div>
            <span className="heading_rating">User Rating</span>
            {Array.from({ length: checkedStar }, (_, index) => (
                    <span key={index} className="fa fa-star checked"></span>
            ))}
            {Array.from({ length: uncheckedStar }, (_, index) => (
                    <span key={index} className="fa fa-star off"></span>
            ))}
        </div> : ''}
        
        {averageRatingRound && averageRatingRound >0 ? <p>{averageRatingRound} average based on {ratingByOthersData?.length} ratings.</p> : ''}
    
            <div className="row m-3">
                <div className="side">
                    <div>5 star</div>
                </div>
                <div className="middle">
                    <div className="bar-container">
                        <div className="bar-5" style={{width: ((ratingByOthersGroupByData?.get(5)?.length || 0)/ ratingCount) * 100}}></div>
                    </div>
                </div>
                <div className="side right">
                    <div>{ratingByOthersGroupByData?.get(5)?.length}</div>
                </div>
                <div className="side">
                    <div>4 star</div>
                </div>
                <div className="middle">
                    <div className="bar-container">
                        <div className="bar-4" style={{width: ((ratingByOthersGroupByData?.get(4)?.length || 0)/ ratingCount) * 100}}></div>
                    </div>
                </div>
                <div className="side right">
                    <div>{ratingByOthersGroupByData?.get(4)?.length}</div>
                </div>
                <div className="side">
                    <div>3 star</div>
                </div>
                <div className="middle">
                    <div className="bar-container">
                        <div className="bar-3" style={{width: ((ratingByOthersGroupByData?.get(3)?.length || 0)/ ratingCount) * 100}}></div>
                    </div>
                </div>
                <div className="side right">
                    <div>{ratingByOthersGroupByData?.get(3)?.length}</div>
                </div>
                <div className="side">
                    <div>2 star</div>
                </div>
                <div className="middle">
                    <div className="bar-container">
                        <div className="bar-2" style={{width: ((ratingByOthersGroupByData?.get(2)?.length || 0)/ ratingCount) * 100}}></div>
                    </div>
                </div>
                <div className="side right">
                    <div>{ratingByOthersGroupByData?.get(2)?.length}</div>
                </div>
                <div className="side">
                    <div>1 star</div>
                </div>
                <div className="middle">
                    <div className="bar-container">
                        <div className="bar-1" style={{width: ((ratingByOthersGroupByData?.get(1)?.length || 0)/ ratingCount) * 100}}></div>
                    </div>
                </div>
                <div className="side right">
                    <div>{ratingByOthersGroupByData?.get(1)?.length}</div>
                </div>
            </div>
        </>
    );
}

export default Rating;
