import { configureStore } from '@reduxjs/toolkit'
import { counterSlices } from '../slices/counterSlice';
import authSlice from '../slices/authSlice';
import detailsSlice from '../slices/detailsSlice';
import postsSlice from '../slices/postsSlice';
import categoriesSlice from '../slices/categoriesSlice';
import profilesSlice from '../slices/profilesSlice';
import usrSlice from '../slices/usrSlice';
import ratingsSlice from '../slices/ratingsSlice';
import locationSlice from '../slices/locationSlice';
import {splashSlice} from '../slices/splashSlice';
import { spinnerSlice } from '../slices/spinnerSlice';
import { alertSlice } from '../slices/alertSlice';
import { headerSlice } from '../slices/headerSlice';
import analyticSlice from '../slices/analyticSlice';
import paymentSlice from '../slices/paymentSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    details: detailsSlice,
    posts:postsSlice,
    categories:categoriesSlice,
    profile:profilesSlice,
    users: usrSlice,
    ratings:ratingsSlice,
    location:locationSlice,
    analytic:analyticSlice,
    counter: counterSlices,
    splash:splashSlice,
    spinner:spinnerSlice,
    alert:alertSlice,
    header:headerSlice,
    payment:paymentSlice,
  },
});

export default store;
