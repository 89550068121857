import React, { useEffect, useState } from "react";
import NavBar from "../components/Navigation/NavBar";
import { getConfig, getConfigV1, getConstant, handleHeader } from "../services/functions";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import MainContainer from "../components/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import "../notification.css";

const Notification = () => {
    const [state, setState] = useState([]);
    const dispatch = useDispatch();
    const authData = useSelector((state) => state?.auth?.data);

    useEffect(() => {
        handleHeader({ ...getConstant("NOTIFICATIONS") }, dispatch);
        loadData();

    }, []);

    const loadData = async () => {

        try {
            const config = getConfigV1('',authData);
            const res = await axios.get(`${BASE_URL}/api/notification/g/list`, config);
            console.log(res?.data);

            if (res?.data) {
                localStorage.setItem("notification", JSON.stringify(res.data));
                setState(res.data);
            }

        } catch (error) {
            console.log(error?.message)
        }
    }

    return (

        <>
            <NavBar />
            <MainContainer>
            <h3>Notifications</h3>
                <div className="notification-page">
                    <ul className="notification-list">
                        {state.map(notification => (
                            <li key={notification.id} className="notification-item bg-light mb-2 p-1 border border-light rounded">
                                <h5 className="capitalize">{notification.title}</h5>
                                <div>{notification.message}</div>
                                <span className="small">{new Date(notification.createdAt).toLocaleString()}</span>
                            </li>
                        ))}
                        {!state?.length && (<li className="notification-item bg-light mb-2 p-1 border border-light rounded">No notifications</li>)}
                    </ul>
                    
                </div>
            </MainContainer>
        </>
    );
};

export default Notification;
