import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../utility/path";
import { updateDetailsAction } from "./detailsSlice";
import { spinner } from "./spinnerSlice";
import { getConfig, handleErrorResponse } from "../../services/functions";

// action
export const getPaymentRequestStatus = createAsyncThunk(
    "payment/getPaymentRequestStatus",
    async (req, { rejectWithValue, getState, dispatch }) => {
     
      try {
            dispatch(spinner(true));
            const config = getConfig();    
            let formData = new FormData();
            formData.append("id",req.id);
            formData.append("txnId",req.txnId);
            formData.append("type",req?.type);
            formData.append("plan",req?.plan);
            formData.append("state",req?.state);
            formData.append("ptid",req?.ptid);

        const { data } = await axios.post(
          `${BASE_URL}/api/payments/getPaymentRequestStatus`,
          formData,
          config
        );
        dispatch(spinner(false));
        return data;
  
      } catch (error) {
        console.log(error);
        dispatch(spinner(false));
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }
    }
);

export const getPaymentDetails = createAsyncThunk(
  "payment/getPaymentDetails",
  async (req, { rejectWithValue, getState, dispatch }) => {
   
    try {
          dispatch(spinner(true));
          const config = getConfig();    
          let formData = new FormData();
          formData.append("id",req.id);
          formData.append("paymentRequestId",req.paymentRequestId);
          formData.append("paymentId",req.paymentId);
          formData.append("paymentStatus",req.paymentStatus);

      const { data } = await axios.post(
        `${BASE_URL}/api/payments/getPaymentDetails`,
        formData,
        config
      );
      dispatch(spinner(false));  
      return data;

    } catch (error) {
      console.log(error);
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);
    }
  }
);
  
//slices
const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    data: {},
  },
  extraReducers: builder => {
    //
    builder.addCase(getPaymentRequestStatus.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getPaymentRequestStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getPaymentRequestStatus.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    builder.addCase(getPaymentDetails.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getPaymentDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getPaymentDetails.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});
  
export default paymentSlice.reducer;
  
