import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConfig, getConstant, handleErrorResponse, handleHeader, inputTypeNumber, validateMobile } from "../services/functions";
import MainContainer from "../components/MainContainer";
import { spinner } from "../redux/slices/spinnerSlice";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import { setHeader } from "../redux/slices/headerSlice";
import NavBar from "../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../redux/slices/alertSlice";
import CustomIcon from "../components/CustomIcon";

const ManageSubscriptionPlan = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth.data);

  const [state, setState] = useState({});
  const [plans, setPlans] = useState([]);
  const [messages, setAddMessage] = useState([]);

  const handleClick = async (e) => {
    e.preventDefault();

    const titleExists = plans.some(item => item.title === state?.title?.trim());
    if (titleExists) {
      const errorResponse = handleErrorResponse({ message: "Plan already exists!!",success:false }, dispatch);
      return;
    }

    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("title", state.title.trim());
      formData.append("messages", JSON.stringify(messages));
      formData.append("amount", state.amount);

      const { data } = await axios.post(`${BASE_URL}/api/subscriptionmaster/add`, formData, config);
      dispatch(spinner(false));
      //navigate(CONTACT_US_PATH);
      dispatch(showAlert({ message: data.message, success: true }));
      setAddMessage([]);
      initPlanData();
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  }

  const handleChange = input => e => {
    //e.preventDefault();
    if (input == 'amount') {
      const truncatedValue = inputTypeNumber(e.target.value, 4);
      setState({ ...state, [input]: truncatedValue });
    } else {
      setState({ ...state, [input]: e.target.value });
    }
  }

  useEffect(() => {
    console.log('useeffect')
    handleHeader({ ...getConstant("MANAGE_SUBSCRIPTION_PLAN") }, dispatch);
    initPlanData();
    return () => { }
  }, []);

  const initPlanData = async () => {
    //const data = localStorage.getItem("subscriptionmasterplan") && JSON.parse(localStorage.getItem("subscriptionmasterplan"));
    // if (data) {
    //     setPlans(data);
    //     return;
    // }

    try {
      const config = getConfig();
      const res = await axios.get(`${BASE_URL}/api/subscriptionmaster/list`, config);
      
      if (res?.data?.length) {
        //localStorage.setItem("subscriptionmasterplan", JSON.stringify(res.data));
        setPlans(res.data);
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const handleAddMessage = () => {
    if (!state?.message) {
      return;
    }

    if (state?.message?.trim() !== '' && !messages.includes(state?.message)) {
      const newItem = state.message;
      const updatedArray = [...messages, newItem];
      setAddMessage(updatedArray);
      setState({ ...state, "message": "" });
    } else {
      console.log('skip add of new message');
      setState({ ...state, "message": "" });
    }

  }

  const handleDeleteMessage = (index) => {
    const updatedArray = [...messages];
    updatedArray.splice(index, 1);
    setAddMessage(updatedArray);
  };

  const handleDeletePlan = async (index) => {
    if (!index) {
      return;
    }
    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("id", index);

      const { data } = await axios.put(`${BASE_URL}/api/subscriptionmaster/put`, formData, config);
      dispatch(spinner(false));
      dispatch(showAlert({ message: data.message, success: true }));
      initPlanData();
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  };


  return (
    <>
      <NavBar />
      <MainContainer>
        <h3>Manage Subscription Plan</h3>
        {authData?.id ?
          <>
            <CustomInput title="Title" id="titleId" type="text" placeholder="Title" handleChange={handleChange} name="title" value={state?.title} addCssToInput={"capitalize"} />
            <CustomInput title="Title" id="amountId" type="number" placeholder="Amount" handleChange={handleChange} name="amount" value={state?.amount} />

            <div className="row">
              <div className="col-9">
                <CustomInput title="Message" id="messageId" type="text" placeholder="Type your plan's description" handleChange={handleChange} name="message" value={state?.message} />
              </div>
              <div className="col m-2">
                <button className="btn btn-primary btn-sm" disabled={!state?.message || messages?.length > 10} onClick={handleAddMessage}>+</button>
              </div>
            </div>

            <button className="btn btn-primary mb-2" onClick={(e) => handleClick(e)} disabled={(!messages?.length || !state.title || !state.amount)} >Submit</button>

            {messages?.length > 0 && <div className="card mt-2">
              <div className="card-header">
                <h5>Plan description</h5>
              </div>

              <ul className="list-scrollable bg-light">
                {messages.map((message, index) => (
                  <li key={index} className="d-flex align-items-center justify-content-between m-1 p-1">
                    <div className="">{message}</div>
                    <button className="btn btn-outline-danger btn-sm mb-2" onClick={() => handleDeleteMessage(index)}>Delete</button>
                  </li>
                ))}
              </ul>
            </div>}

            <div className="card mt-2">
              <div className="card-header">
                <h5>Plans (Active)</h5>
              </div>
              <div id="accordion" className="mt-3 mb-3">
                {
                  plans?.map((r, index) => (
                    <div className="card" key={r.id}>
                      <div className="card-header" id={`headingOne_${r.id}`}>
                        <h5 className="mb-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <button className="btn link-primary collapsed capitalize" data-toggle="collapse" data-target={`#collapseOne_${r.id}`} aria-expanded="false" aria-controls={r.id}>
                            {r.title}
                          </button>
                          <button className="btn btn-outline-danger btn-sm mb-2" onClick={() => handleDeletePlan(r.id)}>Delete</button>
                        </h5>
                      </div>

                      <div id={`collapseOne_${r.id}`} className="collapse" aria-labelledby={`headingOne_${r.id}`} data-parent="#accordion">
                        <div className="card-body">
                          <h1 className="card-title"><CustomIcon type="currency" />{r.amount}</h1>
                          <ul style={{ listStyleType: 'disc' }} className="p-2">
                            {r?.messages && r.messages?.map((i, index) => (
                              <li key={index}>{i}</li>
                            ))}
                          </ul>

                        </div>
                      </div>
                    </div>
                  ))
                }
                {!plans || !plans.length && <div>No records found</div>}
              </div>
            </div>
          </>
          : ''}
      </MainContainer>
    </>
  );
}

export default ManageSubscriptionPlan;
